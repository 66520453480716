@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/buttonLinkMixins";
@import "../mixins/typeMixins";

.categoryChildrenNav {
    user-select: none;
    margin-bottom: 16px;
}

.categoryChildrenNav__opener {
    display: none;
}

.categoryChildrenNav__list {
    margin: 0;
    padding: 0;

    &.--nextLevel {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        gap: 8px;
    }
}

.categoryChildrenNav__node {
    display: flex;
    flex-direction: column;

    &.--nextLevel {
        //border-bottom: 1px solid var(--sf-line-color-light);
    }

    &--showAll {
        display: none;
    }
}

.categoryChildrenNav__link {
    display: none;

    &.--nextLevel {
        display: flex;
        text-decoration: none !important;
        color: var(--sf-sidebar-nav-link-text-color);
        @include blockButton();
        @include blockButton--hoverable();
        align-items: center;
        position: relative;
        height: 48px;
        //white-space: nowrap;
        font-weight: var(--sf-font-weight-semibold);
        //padding: 0 8px;
        padding: 0 12px;
    }

    &.--listWithImages {
        height: 72px !important;
    }
}

.categoryChildrenNav__nameSuffix {
    display: block;
    font-size: $font-size-088rem;
    opacity: .5;
    font-weight: var(--sf-font-weight-normal);
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.categoryChildrenNav__iconImage {
    width: 64px;
    height: 4rem;
    background-color: white;
    border-radius: var(--sf-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    box-sizing: content-box;

    picture {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        max-height: 64px;
        max-width: 64px;
        width: auto;
        height: auto;
        object-fit: cover;
    }
}

.categoryChildrenNav--vehicleRoot {
    .categoryChildrenNav__list--root {
        display: grid;
        grid-template-columns: repeat(var(--layout-main-column-xlg-count, 3), 1fr);
        gap: 8px;
    }

    .categoryChildrenNav__list--2 {
        padding: 12px 16px 16px 16px;
        background-color: var(--sf-category-children-nav-list-bg-color);
        border-radius: var(--sf-border-radius);
        flex-grow: 1;
        display: var(--sf-category-children-nav-list-display);
    }

    .categoryChildrenNav__node--2 {
        position: relative;
        padding-block: 2px;
        &:not(.--listWithImages) {
            padding-inline-start: 24px;
        }

        &.--listWithImages {
            border-bottom: 1px solid var(--sf-line-color-light);
        }

        &:not(.categoryChildrenNav__node--showAll):not(.--listWithImages):before {
            position: absolute;
            left: 0;
            @include bulletContent(0);
        }

        &.categoryChildrenNav__node--showAll {
            display: block;
            margin-top: auto;
        }
    }

    .categoryChildrenNav__link--root {
        background-color: var(--sf-category-children-nav-link-root-bg-color);
    }

    .categoryChildrenNav__link--2 {
        &:not(.categoryChildrenNav__link--showAll) {
            @include linkUnderlineOnHover();
        }

        &.categoryChildrenNav__link--showAll {
            @include linkUnderlinedSubtleLine();
            color: var(--sf-text-color);
            margin-top: 16px;
        }

        font-weight: var(--sf-font-weight-semibold);
        align-self: flex-start;
    }
}

.categoryChildrenNav__tag {
    position: absolute;
    right: 16px;

    &--2 {
        right: 0;
    }
}