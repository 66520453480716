:root {
    --ksfdef-font-family-system-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; /* @const DO NOT CHANGE */
    --ksfdef-font-family-monospace-system-stack: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* @const DO NOT CHANGE */
    --ksfdef-parent-bg-color: var(--sf-body-bg-color); /* @const DO NOT CHANGE */
    --ksfdef-parent-text-color: var(--sf-text-color); /* @const DO NOT CHANGE */
    --ksfdef-layout-default-column-width: 232px; /* @const DO NOT CHANGE */
    --ksfdef-z-index-header: 20; /* @const DO NOT CHANGE */
    --ksfdef-z-index-header-backdrop: 10; /* @const DO NOT CHANGE */
    --ksfdef-z-index-chat-bubble: 25; /* @const DO NOT CHANGE */
    --ksfdef-z-index-dropdown: 30; /* @const DO NOT CHANGE */
    --ksfdef-z-index-modal-backdrop: 40; /* @const DO NOT CHANGE */
    --ksfdef-z-index-modal-dialog: 50; /* @const DO NOT CHANGE */
}