@import "../constants";
@import "../mixins/formMixins";
@import "../mixins/blockButtonMixins";

.shoppingCartBonusSystem {
}


.shoppingCartBonusSystem__info {
    display: flex;
    align-items: stretch;
}

.shoppingCartBonusSystem__info__icon {
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    opacity: .5;
    display: flex;
}

.shoppingCartBonusSystem__info__list {
    font-size: .9rem;
    display: block;
    b {
        font-weight: var(--sf-font-weight-bold);
    }
    li {
        white-space: nowrap;
        display: inline-block;
        &:not(:first-child) {
            margin-inline-start: 8px;
            &:before {
                content: '●';
                opacity: .35;
                font-size: .65em;
                margin-inline-end: 8px;
            }
        }
    }
}

.shoppingCartBonusSystem__button {
    margin-top: 6px;
    @include blockButton();
    @include blockButton--hoverable();
    padding: 6px;
    min-height: var(--sf-form-control-height);
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: var(--sf-font-weight-semibold);
    font-size: .95rem;

    &.--active {
        @include blockButton--activeGreen();
        //color: $color-green;
    }

    .svgicon {
        margin: 0 12px;
        opacity: .25;
    }
}

.shoppingCartBonusSystem__button__checkbox {
    margin-inline-end: 12px;
}

.shoppingCartBonusSystem__button__money {
    margin-inline-start: auto;
    color: $color-green;
}