@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/blockButtonMixins";
@import "../mixins/scrollMixins";

.vehicleTypeSwitcher {
    margin-bottom: 32px;
    &.--modal {
        margin-top: 32px;
    }
}

.vehicleTypeSwitcher__buttons {
    user-select: none;
    width: 100%;
    display: flex;
    padding: 1px;
}

.vehicleTypeSwitcher__btn {
    flex-basis: 33.3%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    font-weight: var(--sf-font-weight-semibold);
    color: var(--sf-sidebar-nav-link-text-color);
    white-space: nowrap;
    @include blockButton();
    @include blockButton--hoverable();

    margin-bottom: 4px;

    &:not(:last-child) {
        margin-right: 8px;
    }

    picture {
        display: contents;
    }

    img {
        max-width: 24px;
        margin-right: 16px;
        height: auto;
    }

    .svgicon {
        margin-right: 16px;
        flex-shrink: 0;
        @include svgicon-color(var(--sf-sidebar-nav-link-icon-color));
    }

    &.--selected {
        @include blockButton--current();
    }
}