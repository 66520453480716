@import "../constants";
@import "../mixins/buttonLinkMixins";
@import "../mixins/headingMixins";

.productAvailabilityModalButton {

}

.productAvailabilityModalButton__button {
    white-space: nowrap;

    &.--noDetail {
        @include btnLinkUnderlinedTextColor();
        margin-inline-start: 0;
        font-size: $font-size-088rem;
        text-align: start;
        white-space: normal;
    }

    &.--detail {
        @include linkUnderlinedSubtleLine();
        font-weight: var(--sf-font-weight-semibold);
        margin-inline-start: 40px;
        margin-block: 8px;
    }
}

.productAvailabilityModal {

}

.productAvailabilityModal__deliveryDateTimes {
    margin-top: 16px;
}

.productAvailabilityModal__deliveryOption {
    border-top: 1px solid var(--sf-line-color-light);
    display: flex;
    padding: 12px 0;

    &:last-of-type {
        padding-bottom: 0;
    }

    &--hidden {
        display: none;
    }
}

.productAvailabilityModal__deliveryOptionImage {
    width: 60px;
    margin-right: 24px;
    flex-shrink: 0;
    img {
        overflow: hidden;
        border-radius: var(--sf-border-radius);
        max-width: 100%;
        object-fit: contain;
        -webkit-user-drag: none;
        user-select: none;
    }
}

.productAvailabilityModal__deliveryOptionText {

}

.productAvailabilityModal__deliveryName {
    font-weight: var(--sf-font-weight-semibold);
    @media (min-width: $screen-600px) {
        @include heading;
        @include heading--h5;
    }
    margin-bottom: 8px !important;
}

.productAvailabilityModal__description {
    color: var(--sf-text-light-color);
}

.productAvailabilityModal__orderDeadlineHint {
    color: var(--sf-text-light-color);
    font-size: $font-size-088rem;
}

.productAvailabilityModal__orderDeadline {
    font-weight: var(--sf-font-weight-semibold);
}

.productAvailabilityModal__deliveryDateTime {
    font-weight: var(--sf-font-weight-bold);
    color: $color-green;
    margin-left: 1rem;
    white-space: nowrap;
    @media (min-width: $screen-600px) {
        font-size: $font-size-110rem;
        @include heading;
        @include heading--h5;
    }

    &--available {
        color: $color-green;
    }

    line-height: 1.5;
}

.productAvailabilityModal__deliveryTime {
    font-weight: var(--sf-font-weight-normal);
    font-size: 14px;
}