@import "./constants";

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    //noinspection CssOverwrittenProperties
    overflow-x: hidden; // For browsers that doesn't support `clip`.
    //noinspection CssOverwrittenProperties
    overflow-x: clip; // Value `clip` allows `position:sticky` for <header>.
    font-size: var(--sf-font-size-root);
    min-height: 100vh;
    @media (max-width: $screen-900px-1) {
        font-size: unquote("calc(var(--sf-font-size-root) - 1px)");
    }
}

body {
    font-family: var(--sf-font-family), var(--ksfdef-font-family-system-stack);
    font-variant-numeric: lining-nums slashed-zero;
    line-height: $line-height;
    color: var(--sf-text-color);
    letter-spacing: var(--sf-letter-spacing);
    word-spacing: 0.04em;
    background-color: var(--sf-body-bg-color);
    scroll-behavior: smooth;
    //noinspection CssOverwrittenProperties
    overflow-x: hidden; // For browsers that doesn't support `clip`.
    //noinspection CssOverwrittenProperties
    overflow-x: clip; // Value `clip` allows `position:sticky` for <header>.
    min-height: 100vh;
    @media (max-width: $screen-600px-1) {
        &.--overlayActive {
            height: 100vh;
            box-sizing: border-box;
        }
    }

    &:before {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 0;
        background-color: var(--sf-body-backdrop-bg-color);
        opacity: 0;
        z-index: var(--ksfdef-z-index-header-backdrop);
        transition: opacity $transition-duration, height 0 linear 0;
        display: var(--sf-body-backdrop-display);
    }

    &.--overlayActive:before {
        backdrop-filter: blur(var(--sf-body-backdrop-blur));
        transition: opacity $transition-duration, height 0 linear 0;
        height: 100vh;
        opacity: 1;
    }

    &.--overlayDisabled:before {
        display: none !important;
        backdrop-filter: none !important;
        background-color: transparent !important;
    }
}

//::selection {
//}

[title] {
    cursor: help;
}

[hidden] {
    display: none !important;
}

dialog {
    outline: none !important;
}

a, button, input[type=submit] {
    outline: none;
    color: var(--sf-link-color);
    //-webkit-tap-highlight-color: var(--sf-color-primary);
    text-decoration: none;
    letter-spacing: inherit;
    cursor: pointer !important;
}

a, button, input, select, textarea, label, summary {
    touch-action: manipulation;
}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

img, svg {
    -webkit-user-drag: none;
}

address {
    font-style: normal;
}

strong, b {
    font-weight: var(--sf-font-weight-semibold);
}

.tracy-row {
    @media (max-width: $screen-600px-1) {
        flex-direction: column;
    }
}

html.--mobileApp #smartsupp-widget-container {
    display: none !important;
}