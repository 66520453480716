@import "../constants";
@import "../mixins/verticalMarginMixins";
@import "../mixins/headingMixins";
@import "../mixins/blockButtonMixins";
@import "../mixins/blinkerMixins";

.orderBankTransferDetails__qrCode {
    display: grid;
    justify-content: center;
    border: 1px solid var(--sf-line-color-light);
    border-radius: var(--sf-border-radius);
    margin-block-start: 1lh;

    img {
        aspect-ratio: 1/1;
        max-width: 320px;
    }
}

.orderBankTransferDetails__qrCodeDownload {
    text-align: center;
}

.orderCartOverview {
    @include verticalMarginBottom;
    padding-bottom: 1rem;
    user-select: text;
    display: grid;
    gap: 1lh;

    h2 {
        @include heading;
        @include heading--h3;
        line-height: $line-height;
        border: none;
        white-space: nowrap;
        margin: 0;
    }

    @media (min-width: $screen-900px) {
        width: 50%;
        margin-inline: 25%;
    }


    table {
        width: 100%;
        border-spacing: 0;

        th, td {
            vertical-align: top;
            padding-inline-end: 10px;
            line-height: $line-height-150;
        }

        th, td:first-child {
            font-weight: var(--sf-font-weight-normal);
            position: relative;
            text-align: start;
            width: 240px;
        }

        td, td > b {
            font-weight: var(--sf-font-weight-bold);
        }

        @media (max-width: $screen-900px) {
            tr {
                display: flex;
            }
            th, td:first-child {
                width: auto;
                white-space: nowrap;
            }
        }
    }
}

.orderCartOverview__block,
.orderBankTransferDetails__bankTransferDetails {
    background-color: var(--sf-pane-bg-color);
    border-radius: var(--sf-border-radius);
    padding: 16px;
}

.orderCartOverview__storeLogo {
    width: 80px;
    border-radius: var(--sf-border-radius);
    margin-bottom: 16px;
}

.orderCartOverview__storePhotos {
    margin-top: 1lh;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    img {
        aspect-ratio: 1/1;
        width: 120px;
        object-fit: cover;
        border-radius: var(--sf-border-radius);
    }
}

.orderCartOverview__storeOpeningHours {
    margin-top: 1lh;

    table {
        width: auto;
    }

    th, td {
        white-space: initial;
    }
}

.orderCartOverview__deliveryDateTime {
    position: relative;
    padding: 8px 16px 8px 40px;
    background-color: var(--sf-text-color);
    border-radius: var(--sf-border-radius);
    box-shadow: var(--sf-block-shadow);
    display: flex;
    flex-wrap: wrap;
    gap: 0 16px;
    align-items: center;
    min-height: var(--sf-btn-xlg-height);

    strong, h3 {
        @include heading--h2();
        color: var(--sf-body-bg-color) !important;
    }

    &:before {
        @include blinkerDot($color-red);
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -6px;
    }
}

.kvikymart-shopping-cart-submit {
    flex-basis: 100%;
    @media (min-width: $screen-600px) {
        flex-basis: 50%;
    }
}