@import "../constants";
@import "../mixins/svgiconMixins";

.pagePartNews {
}

.pagePartNews__heading {
    // cross style fot .heading .heading--h2
    padding-bottom: calc(4px + var(--sf-article-list-gap));
    border-bottom: 1px solid var(--sf-article-list-separator-line-color);
    margin-bottom: var(--sf-article-list-gap);
}

.pagePartNews__next {
    padding-bottom: 8px;
    .svgicon {
        transform: scale(.5) translateX(50%);
    }
}