@import "../constants";
@import "../mixins/buttonLinkMixins";

.parametersTable {
    width: 100%;
    padding: 0;

    table, tbody {
        display: block;
        width: 100%;
    }

    tr {
        display: flex;
        width: 100%;
        padding: 0 12px;
        border-radius: var(--sf-border-radius);

        &:nth-child(odd) {
            background-color: var(--sf-pane-bg-color);
        }
    }

    th, td {
        vertical-align: top;
        padding-top: 6px;
        padding-bottom: 6px;
        text-align: left;
        display: block;
        width: auto;

        &:dir(rtl) {
            text-align: right;
        }
    }

    th {
        font-weight: var(--sf-font-weight-normal);
        padding-inline-end: 8px;
        display: block;
        flex-shrink: 1;
        color: var(--sf-text-light-color);
        min-width: calc(max(120px, 33%));
    }

    td {
        font-weight: var(--sf-font-weight-semibold);
        padding-inline-start: 8px;
    }

    a {
        @include linkUnderlineOnHover();
    }
}

.parametersTable.--twoColumns tbody {
    @media (min-width: $screen-900px) {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 0 32px;
        tr {
            background-color: unset;
        }
        tr:nth-child(4n),
        tr:nth-child(4n+1) {
            background-color: var(--sf-pane-bg-color);
        }
    }
}