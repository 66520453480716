@import "../constants";
@import "../mixins/typeMixins";
@import "../mixins/buttonLinkMixins";


.productOeNumbers__brandLine {
    &:nth-child(odd) {
        background-color: var(--sf-pane-bg-color);
    }
    padding: 6px 12px;
    border-radius: var(--sf-border-radius);
    display: flex;
}

.productOeNumbers__heading {
    // cross style for .heading
    flex: 0 0 180px;
    flex-shrink: 0;
    @include text-overflow-ellipsis();
    line-height: inherit;
    display: inline;
    opacity: .65;
}

.productOeNumbers__numbers {
    flex-grow: 1;
    display: inline-grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 4px;
}

.productOeNumbers__item {
    padding: 0 12px;
    border-inline-start: 1px solid var(--sf-line-color-100);
}

.productOeNumbers__number {
    direction: ltr;
    color: inherit;
    font-weight: var(--sf-font-weight-bold);
    @include link(var(--sf-text-color));
}

.productOeNumbers__showMoreBtn {
    @include link();
}