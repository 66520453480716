@use "sass:math";
@import "../constants";
@import "../mixins/typeMixins";

.productPrices {
    font-size: $font-size-088rem;
    color: var(--sf-text-color);

    &.--detail {
        @media (min-width: $screen-600px) {
            font-size: 1rem;
        }
    }
}

.productPrices {
    &:not(&.--detail) {
        .productPrices__block {
            justify-content: end;
        }
    }
}

.productPrices__block {
    padding: 0 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .productPrices.--detail & {
        min-height: 32px;
    }

    &.--strikethrough {
        color: $color-red;

        .price__primary {
            position: relative;
            @include text-strikethrough();
        }
    }

    &.--youSave {
        color: $color-green;
    }

    &.--nonPreferred {
        color: var(--sf-text-light-color);
    }

    &.--exchangeRate {
        color: var(--sf-text-light-color);
        font-size: $font-size-088rem;
    }

    &.--lowestPrice {
        color: var(--sf-text-light-color);
        font-size: $font-size-088rem;
    }

    &.--recyclingFee {
        color: var(--sf-text-light-color);
        font-size: $font-size-088rem;
    }
}

.productPrices__coupon {
    margin-inline: 8px;
}

.productPrices__label {
    .productPrices.--detail & {
        flex-basis: 172px;
    }
}

.productPrices__price {
    font-weight: var(--sf-font-weight-bold);
    padding-inline-end: 2px;
}

.productPrices__label + .productPrices__price {
    padding-inline-start: 8px;
}

.productPrices.--detail .productPrices__block.--yourPrice {
    border-radius: var(--sf-border-radius);
    padding-block: 4px;
    background-color: var(--sf-product-prices-your-price-bg-color);

    &.--discount {
        background-color: var(--sf-product-prices-your-price-discount-bg-color);
    }

    .productPrices__price {
        .price__primary {
            font-size: var(--sf-heading-2-font-size);
        }
    }
}

.productPrices.--detail .productPrices__block.--coupon {
    padding-block: 4px;
}

.productPrices .productPrices__block.--yourPrice .productPrices__price .price__primary {
    font-size: $font-size-110rem;
}

.productPrices.--detail .productPrices__block.--yourPrice.--discount {
    font-weight: var(--sf-font-weight-bold);
}

.productPrices.--detail .productPrices__block.--yourPrice.--discount .productPrices__price .price__primary {
    padding: 4px 8px;
    min-height: 32px;
    display: inline-flex;
    border-radius: var(--sf-border-radius);
    background-color: $color-red;
    color: white;
    line-height: 1;
    align-items: center;
}