@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/svgiconMixins";

.categorySelection {
    user-select: none;
}

.categorySelection__heading {
    padding: 8px 0;
}

.categorySelection__form {
    display: block;

    @media (min-width: $screen-760px) {
        display: flex;
        flex-basis: 100%;
    }
}
.categorySelection__selectSteps {
    display: block;
    margin-bottom: 4px;

    @media (min-width: $screen-760px) {
        display: flex;
        flex-basis: 100%;
    }
    .svgicon {
        pointer-events: none;
    }
}

.categorySelection__selectStep {
    flex-grow: 1;
    flex-basis: auto;
    display: block;
    cursor: pointer;
    border-radius: var(--sf-border-radius);
    position: relative;
    height: 64px;
    margin-bottom: 4px;
    @media (min-width: $screen-760px) {
        margin-bottom: 0;
        margin-right: 4px;
    }
    label {
        font-weight: var(--sf-font-weight-semibold);
        font-size: .9rem;
        display: block;
        position: absolute;
        color: var(--sf-text-light-color);
        top: 8px;
        inset-inline-start: 16px;
    }
    i {
        inset-inline-start: 16px;
        top: 32px;
        position: absolute;
        font-size: .9rem;
        color: $color-red;
    }
    select {
        -webkit-appearance: none;
        border: none;
        padding: 18px 16px 0;
        margin: 0;
        width: 100%;
        outline: none;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        font-weight: var(--sf-font-weight-bold);
        font-size: 1rem;
        cursor: pointer;
        &[disabled] {
            cursor: not-allowed;
            background-color: var(--sf-line-color-light);
        }
        &:not([disabled]) {
            @include blockButton();
            @include blockButton--hoverable();
            background-color: transparent !important;
        }
        option {
            font-size: 1rem;
        }
    }

    .svgicon {
        position: absolute;
        inset-inline-end: 16px;
        top: calc( 50% - 6px);
        @include svgicon-size(12px);
        opacity: .5;
    }
}

.categorySelection__spinner {
    visibility: visible;
    height: 100%;
    transform: scale(.5);

    &--hidden {
        visibility: hidden;
    }
}

.categorySelection__formSubmit {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
}

.categorySelection__formSubmit > button {
    width: 100%;
    height: 40px;

    .svgicon {
        opacity: 1;
        transform: scale(.75);
    }
    @media (min-width: $screen-760px) {
        width: 64px;
        height: 64px;
    }
}