@import "../constants";
@import "../mixins/blockButtonMixins";

.pagination {
    text-align: center;
    width: 100%;
    position: relative;
    padding: 32px 0;
    line-height: 40px;
    //border-top: 4px solid var(--sf-line-color-strong);
    user-select: none;
    display: flex;
    justify-content: space-evenly;
}

.pagination__pages {
    margin: 0 110px 0 140px;
}

.pagination__link__page {
    display: none;
}

.pagination__link {
    display: inline-block;
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-block-button-bg-color);
    @include blockButton();
    @include blockButton--hoverable();

    &--number {
        padding: 0 16px;
        margin-bottom: 4px;
        font-weight: var(--sf-font-weight-bold);
    }

    &--current {
        @include blockButton--current();
    }

    &--prev {
        position: absolute;
        left: 0;
        top: 32px;
        width: 128px;
    }

    &--next {
        position: absolute;
        right: 0;
        top: 32px;
        width: 96px;
        font-weight: var(--sf-font-weight-bold);
    }

    @media (max-width: $screen-600px-1) {
        &--other {
            display: none;
        }

        &--current {
            background-color: transparent;
            color: var(--sf-line-color-strong);

            .pagination__link__page {
                display: inline;
            }
        }
    }
}