@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/typeMixins";

.layoutFooter {
    contain: content;
    margin-top: 1.5rem;
    background-image: var(--sf-layout-footer-bg-image);
    background-size: var(--sf-layout-footer-bg-size);
    background-position: var(--sf-layout-footer-bg-position);
    background-repeat: var(--sf-layout-footer-bg-repeat);
    background-color: var(--sf-layout-footer-bg-color);
    box-shadow: var(--sf-layout-footer-box-shadow), 0 100vh 0 100vh var(--sf-layout-footer-bg-color);
    //border-top: 1px solid var(--sf-line-color-light);
    user-select: none;
    position: relative;
    color: var(--sf-layout-footer-text-color);
    --ksfdef-parent-bg-color: var(--sf-layout-footer-bg-color);
    --ksfdef-parent-text-color: var(--sf-layout-footer-text-color);
}

.layoutFooter__page {
    contain: content;
    background-color: var(--sf-layout-footer-page-bg-color);
    color: var(--sf-layout-footer-page-text-color);
    --ksfdef-parent-bg-color: var(--sf-layout-footer-page-bg-color);
    --ksfdef-parent-text-color: var(--sf-layout-footer-page-text-color);
}


.layoutFooter__links {
    padding: 16px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.layoutFooter__links__cell {
    padding: 6px 0;
    display: flex;
    align-items: center;

    &:not(:last-child):after {
        @include bulletContent(6px);
        @media (min-width: $screen-1200px) {
            @include bulletContent(16px);
        }
    }
}

.layoutFooter__links__link {
    display: block;
    font-weight: var(--sf-font-weight-semibold);
    color: currentColor;
    padding: 0;
    text-align: center;
    text-underline-offset: .5ex;
    @media (hover) {
        &:hover, &:active {
            text-decoration: underline;
        }
    }
}

.layoutFooter__logos {
    margin: 16px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-evenly;
    align-items: center;

    a {
        display: block;
    }
}

.layoutFooter__logos,
.layoutFooter__logos__group {
    display: flex;
    gap: 16px;
    zoom: 1 !important;

    > * {
        flex-shrink: 0;
        zoom: .75;
    }

    @media (min-width: $screen-1200px) {
        gap: 24px;
        > * {
            zoom: 1;
        }
    }
}

.layoutFooter__logos__fundedByEu {
    img {
        display: block;
        height: 33px; // PNG height is 66px
        -webkit-user-drag: none;
    }
}

.layoutFooter__logos__roSoal,
.layoutFooter__logos__roAnpc {
    img {
        display: block;
        height: 34px;
        -webkit-user-drag: none;
    }
}

.layoutFooter__logos__tecdoc {
    width: 120px;
    height: 34px;
    color: inherit; // ! Beacuse it is <a>.
    svg {
        height: 100%;
        width: 100%;
        scale: 1.3;
    }
}

#tecalliance_tecdoc_inside_logo_color {
    display: var(--sf-layout-footer-tecalliance-tecdoc-inside-logo-color-display);
}

#tecalliance_tecdoc_inside_logo_mono {
    display: var(--sf-layout-footer-tecalliance-tecdoc-inside-logo-mono-display);
    color: var(--sf-layout-footer-tecalliance-tecdoc-inside-logo-mono-color);
    opacity: var(--sf-layout-footer-tecalliance-tecdoc-inside-logo-mono-opacity);

    path {
        fill: currentColor;
    }
}

.layoutFooter__logos__paymentLogo {
    display: var(--sf-layout-footer-payment-logos-display) !important;
    height: 24px;
    -webkit-user-drag: none;

    &.--visa,
    &.--revolut,
    &.--klarna {
        height: 20px;
    }
}

.layoutFooter__logos__facebook,
.layoutFooter__logos__instagram,
.layoutFooter__logos__whatsApp {
    color: currentColor;
    opacity: .65;
    display: flex !important;
    align-items: center;
    gap: 2px;
    font-weight: var(--sf-font-weight-semibold);

    .svgicon {
        @include svgicon-size(32px);
    }
}

.layoutFooter__createdBy {
    letter-spacing: 0;
    font-size: calc(1rem - 2px);
    padding-bottom: 64px;
    margin-top: 32px;
    text-align: center;

    a {
        color: var(--sf-layout-footer-text-color);
        opacity: .65;
        text-underline-position: under;

        strong {
            font-weight: var(--sf-font-weight-bold);
        }

        @media (hover) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: $screen-600px-1) {
        font-size: .65rem;
    }
}