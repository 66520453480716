@use "sass:math";

@import "../constants";
@import "./blockButtonMixins";

@mixin formCheckbox() {
    appearance: none;
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    display: inline-block;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .15); //, inset 0 4px 4px var(--sf-line-color-light);
    background-color: white;
    vertical-align: middle;
    flex-shrink: 0;
    flex-grow: 0;

    &:hover, &.--hover {
        //box-shadow: $block-shadow-default;
        background-color: var(--sf-line-color-100);
    }
    &:checked, &.--checked {
        background-color: $color-green;
        box-shadow: var(--sf-block-shadow);
    }
    &:hover, &.--hover,
    &:checked, &.--checked {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAACBElEQVR4Ae3aA2ydURyG8dm2bdu27S1aPCuYGU3RFg3RjBizbdu27afJico3Jzm5/y7fm/zqfjdP3Z6m6Tyhx38lCrIuCjIjCjLg79+/VdAHOVN9EBHD8Rtxe4EBZoOEmLL4gPjbhPymgoSYdNiHpPYYTVJT0ASktIdIZzrIxVTGFyHoFzKbDXIx6XEUyja61zMdNA3KXqKI2SAXUw1foWxgcteyEJMBp6BsnXs900HzoOwJ8psNcjH18APK+irXjGVMZlyEstXu9UwHLYKyR8hrNsjFNMUvIeYPupj99cHFZMMNKFvuXs900DIou4OcZoNcTDv8EWJ+o3XQ31i5geyogPSeMblwD8qWBv0VnBvohFeI2wkU8QhaCWVXkTVYkIuJ/3PWdRSTY7iG+KH2E429YhyPGD3KXSMPHkLZgmB/9eHiLfFV+PAokkLQWig7j0whg47AzS+Kp/eHsu+o4xehB50E84vi8UJ4AWUz/SP0oO745RvFw1uh7CQyhAqKHzUQP6HuOophBJR9Q43QfwqOHzXU4z31BsqmxOBv2x7vKW2HkT4mQQGiPqNizE4fAkSNjtlxSoCo3Ugbk6AAUe9R2uSBl2fUSLMneB5R25HWZJBH1FuUsH/GqkcNtX9orP9EscneKbge1SLen6i2IrvdIP2MtCrKRP+nEAVFQalPFGTdP45VBsBZH+QDAAAAAElFTkSuQmCC);
        background-position: center center;
        background-size: 16px;
        background-repeat: no-repeat;
    }

    &[type="checkbox"]:indeterminate {
    }

    &:disabled {
        pointer-events: none;
        filter: none;
        opacity: .5;
    }

    // Use disabled attribute in addition of :disabled pseudo-class
    // See: https://github.com/twbs/bootstrap/issues/28247
    &[disabled] {
        opacity: .5;
    }
}


@mixin formError() {
    color: white;
    background-color: $color-red;
    border-radius: var(--sf-border-radius);
    padding: 8px 12px;
    font-weight: var(--sf-font-weight-normal);
    margin: 4px 0 20px 0;
    display: block;

    li {
        display: block;
    }

    a {
        color: white;
        text-decoration: underline;
        font-weight: 700;

        @media (hover) {
            &:hover, &:active {
                color: mix(white, $color-red);
            }
        }
    }
}

@mixin formControl {
    display: block;
    width: 100%;
    height: var(--sf-form-control-height);
    padding: 0 12px;
    font-size: 16px; // WARNING. Keep it 16px. When font-size is < 16px,iOS zooms out to the field. That's weird UX.
    line-height: calc(var(--sf-form-control-height) - 2px);
    color: var(--sf-form-control-text-color);
    background-color: var(--sf-form-control-bg-color);
    background-image: var(--sf-form-control-bg-image);
    background-clip: padding-box;
    border: 1px solid var(--sf-form-control-border-color);
    box-sizing: border-box;
    border-radius: var(--sf-border-radius);
    box-shadow: none;
    outline: none;
    font-weight: var(--sf-font-weight-semibold);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;

    &::placeholder {
        color: var(--sf-form-control-placeholder-color);
        font-weight: var(--sf-font-weight-normal);
    }

    &:focus, &.--focus {
        outline: 0;
        @include formControl--focus();
    }

    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        background-color: var(--sf-block-button-bg-color);
        cursor: not-allowed;
        opacity: 1;
    }

    textarea & {
        height: auto;
    }
}

@mixin formControl--focus {
    @include blockButton--current(var(--sf-form-control-focus-border-color));
}

@mixin formControl--labelInside {
    padding: 16px 8px 0;
    height: 52px;
    //line-height: 36px;
    @media (max-width: $screen-600px-1) {
        &::placeholder {
            color: transparent !important;
        }
    }
}

@mixin formControl--select {
    padding-inline-end: 32px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20fill%3D%27black%27%20height%3D%2724%27%20viewBox%3D%270%200%2024%2024%27%20width%3D%2724%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M7%2010l5%205%205-5z%27%2F%3E%3Cpath%20d%3D%27M0%200h24v24H0z%27%20fill%3D%27none%27%2F%3E%3C%2Fsvg%3E"), var(--sf-form-control-bg-image);
    background-repeat: no-repeat, repeat-x;
    background-position-x: calc(100% - 8px);
    background-position-y: 50%;
}