@import "../constants";

@mixin inside-svgicon-scale($scale) {
    .svgicon {
        transform: scale($scale);
    }
}

@mixin svgicon-size($size) {
    width: $size;
    height: $size;
}

@mixin svgicon-color($color) {
    svg {
        path, polygon, circle, rect {
            fill: $color;
        }

        polyline {
            stroke: $color;
        }
    }
}

@mixin svgicon-color-transition($duration) {
    svg {
        path, polygon, circle, rect, polyline {
            transition: fill $duration;
        }

        polyline {
            transition: stroke $duration;
        }
    }
}

.svgicon {
    display: inline-block;
    vertical-align: top;
    pointer-events: none;
    @include svgicon-color(currentColor);
    @include svgicon-size(24px);
}

[dir=rtl] {
    .svgicon--arrowToInlineStart, .svgicon--arrowToInlineEnd {
        svg {
            transform: scale(-1);
        }
    }
}