@import "../constants";

$pointer-size: 4px;
$pointer-size-lg: 8px;

.pointers {
    padding: 16px 0;
}

.pointers__wrapper {
    display: flex;
    justify-content: center;
    font-size: 0;
    margin: -($pointer-size*0.5);
    position: relative;

    &--line {
        margin: 0;
        &:before {
            display: block;
            border-radius: 2px;
            height: $pointer-size;
            width: 100%;
            left: 0;
            top: 0;
            content: "";
            position: absolute;
            background-color: var(--sf-text-color);
            opacity: .1;
        }
    }
}

.pointer {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: var(--sf-text-color);
    opacity: .1;
    display: inline-block;
    height: $pointer-size;
    flex-basis: 1.5rem;
    //flex-grow: 1;
    margin: 0 ($pointer-size * 0.5);
    border-radius: 2px;
    @media (min-width: $screen-600px) {
        flex-basis: 32px;
    }
    @media (min-width: $screen-1200px) {
        //height: $pointer-size-lg;
        flex-basis: 48px;
    }
    text-indent: -9999px;
    transition: opacity .35s $transition-timing-function;

    .pointers__wrapper--line & {
        opacity: 0;
        flex-grow: 1;
        margin: 0;
    }

    @media (hover) {
        &:hover {
            opacity: .5;
            box-shadow: var(--sf-block-shadow);
        }
    }

    &--current {
        opacity: 1 !important;
    }
}