@import "../mixins/typeMixins";
@import "../mixins/svgiconMixins";

.formatedText {
    @include htmlContent();
}

.formatedPerex {
    @include htmlContent();
    margin: 12px 0;
    font-size: var(--sf-font-size-large);

    p, ul, ol {
        margin-bottom: 0;
    }
}