@import "../constants";

.shoppingCartOffer {
    padding: 8px 12px 12px;
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-pane-bg-color);
}

.shoppingCartOffer__heading {
    font-size: 1rem;
    margin-bottom: 6px;
}

.shoppingCartOffer__form {
    display: flex;
    align-items: flex-end;
}

.shoppingCartOffer__option {
    margin-inline-end: 16px;
    &:not(:last-of-type) {
        margin-bottom: 4px;
    }
    label {
        display: flex;
        align-items: center;
        font-size: $font-size-088rem;
        input {
            flex-shrink: 0;
            margin-inline-end: 8px;
        }
    }
}

.shoppingCartOffer__priceSurcharge {
    margin-inline-start: auto;
    margin-inline-end: 6px;
    width: 120px;
    display: grid;
    justify-content: stretch;
    label {
        font-size: $font-size-088rem;
    }
}