@import "../constants";
@import "../mixins/typeMixins";

$btn-height: 2.5rem;

.simpleNav {
    ul {
        li {
            display: block;
            border-bottom: 1px solid var(--sf-line-color-light);

            a {
                padding: 2px .5rem;
                @include text-overflow-ellipsis();
                display: block;
                line-height: $btn-height;
                font-weight: 700;
            }

            @media (hover) {
                & a:hover, & a:active {
                    text-decoration: underline;
                }
            }
        }

        @media (min-width: $screen-600px) {
            display: flex;
            flex-wrap: wrap;
            li {
                flex: 0 0 50%;
            }
        }
    }

    &--twoColumnSmOnly {
        @media (min-width: $screen-900px) {
            ul li {
                flex: 0 0 100%;
            }
        }
    }
}