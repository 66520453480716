@import "../constants";
@import "../mixins/imageMixins";
@import "../mixins/buttonLinkMixins";

.productDetail {
    margin: 16px 0;
}

.productDetail__layout {
    display: grid;
    gap: 32px;
    align-items: stretch;
    @media (min-width: $screen-900px) {
        grid-template-columns: 1fr 1fr;
    }
    margin: 32px 0;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--sf-line-color-light);
}

.productDetail__layout__imageCell {
    position: relative;
}

.productDetail__layout__detailsCell {

}

.productDetail__image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-wrap: wrap;
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;

    picture {
        display: contents;

        img {
            position: absolute;
            display: block;
            max-width: 90%;
            max-height: 90%;
            height: auto;
            object-fit: contain;
            -webkit-user-drag: none;
            image-rendering: auto;
            -ms-interpolation-mode: bicubic;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &.productDetail__image__iconImg {
                transform: translate(-50%, -50%) scale(.5);
            }
        }
    }

    @include imageOverlay();
}

.productDetail__image__oemText {
    margin-top: 16px;
}

a.productDetail__image {
    @include imageEnlargeCursor();
}


.productDetail__brandIconImg {
    position: absolute;
    right: 8px;
    top: 8px;
    user-select: none;
    mix-blend-mode: multiply;
    max-width: 96px;
    height: auto;
    display: block;
    max-height: 60px;
    object-fit: contain;
    object-position: right top;
    pointer-events: none;
}


.productInline__heading {
    a {
        @include link();
    }
}

.productText {
    margin-bottom: 32px;
}

.productSuppliesOverview {
    background-color: white;
    margin: 32px auto;
    padding: 0 10px 10px;
    border: 1px solid var(--sf-line-color-100);
    border-radius: var(--sf-border-radius);
}

.productAdditionalImages {
    margin-top: 8px;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    @media (min-width: $screen-600px) {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
}

.productDetail__additionalImage {
    @include imageOverlay();
    @include imageEnlargeCursor();
    aspect-ratio: 1/1;

    picture {
        display: contents;

        img {
            background-color: white;
            border-radius: var(--sf-border-radius);
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            -webkit-user-drag: none;
            opacity: 0;
            transition: opacity $transition-duration;

            &.loaded {
                opacity: 1;
            }
        }
    }
}

.productDetail__files {
    margin: 32px 0;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.productDetail__file {

}

.productDetail__bottomInfo {
    opacity: .5;
    margin: 24px 0;
    font-style: italic;
}

.productDetail__parameters {
    margin-bottom: 32px;
}

.productDetail__parameters__table {
    display: block;
}

.productDetail__linked {
    display: grid;
    gap: 8px;
    margin-top: 32px;
}