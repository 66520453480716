@import "../constants";
@import "../mixins/blockButtonMixins";


kvik-vehicle-result {
    margin: 4px 0;
    display: block;
}

.vehicleResult__link {
    display: flex;
    overflow: hidden;
    align-items: center;
    @include blockButton();
    @include blockButton--hoverable();
}

.vehicleResult__brandLogoPicture {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    flex-basis: calc(100% / 6);
    padding: .25rem 0;
    padding-inline-start: .75rem;
    @media (min-width: $screen-480px) {
        flex-basis: calc(100% / 8);
    }
}

.vehicleSearchResult__vehicleImg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.vehicleSearchResult__brandLogoImg {
    max-width: 64px;
    max-height: 100%;
    object-fit: contain;
}

.vehicleResult__carIcon {
    opacity: .5;
    margin-inline-end: 1em;
    display: inline-block;
    height: 24px;
    color: var(--sf-text-color);
    @media (max-width: $screen-600px-1) {
        display: none;
    }
}

.vehicleResult__textWrapper {
    align-items: center;
    flex-wrap: wrap;
    font-size: $font-size-088rem;
    padding: .5em 0;
    direction: ltr;
    padding-inline-start: .75rem;
    @media (min-width: $screen-600px) {
        display: flex;
        font-size: 1rem;
    }
}

.vehicleResult__title {
    font-weight: var(--sf-font-weight-semibold);
    margin-inline-end: auto;
    padding-inline-end: 1em;
    @media (max-width: $screen-900px-1) {
        flex-basis: 100%;
    }
}

.vehicleResult__motorization,
.vehicleResult__power {
    padding-inline-end: 1em;
    white-space: nowrap;
}

.vehicleResult__motorCodes,
.vehicleResult__years,
.vehicleResult__vin,
.vehicleResult__licensePlate {
    padding-inline-end: 1em;
    white-space: nowrap;
    color: var(--sf-text-color);
    @media (max-width: $screen-600px-1) {
        display: none;
    }
}

.vehicleResult__motorCodes,
.vehicleResult__years,
.vehicleResult__vin {
    opacity: .5;
}

.vehicleResult__arrow {
    padding-inline-end: .5rem;
    opacity: .5;
    margin-inline-start: auto;
    color: var(--sf-text-color);

    .svgicon {
        transform: scale(.5);
    }
}