@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/scrollMixins";


.breadcrumbNav {
    .kvikymart-breadcrumb {
        font-size: $font-size-088rem;
        height: 40px;
        white-space: nowrap;
        box-sizing: content-box;
        display: flex;
        align-items: center;

        @media (min-width: $screen-tablet) {
            height: var(--sf-breadcrumb-height);
        }

        a {
            flex-shrink: 0;
            color: currentColor;
            font-weight: var(--sf-font-weight-normal);

            &:last-child {
                opacity: .65;
            }

            @media (hover) {
                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 4px;
                    opacity: 1;
                }
            }
        }

        .kvikymart-breadcrumb-separator {
            padding: 0 12px;
            height: 10px;
            flex-shrink: 0;

            .svgicon {
                @include svgicon-size(8px);
                opacity: .65;
            }
        }

        .kvikymart-breadcrumb-frontpage {
            display: flex;
            flex-shrink: 0;
            opacity: .65;

            .svgicon {
                @include svgicon-size(12px);
            }
        }
    }
}