@use "sass:math";

@import "../constants";
@import "../mixins/formMixins";
@import "../mixins/spinnerMixins";

.km-form {
    .select2-container {
        width: 100%;
        display: block;
    }

    .select2-selection {
        @include formControl();
        display: flex !important;
        padding-right: 4px !important;
        align-items: center;
        //@media (max-width: $screen-600px-1) {
        //    @include formControl--labelInside();
        //}
        border-top-left-radius: var(--sf-border-radius) !important;
        border-top-right-radius: var(--sf-border-radius) !important;
        border-bottom-left-radius: var(--sf-border-radius) !important;
        border-bottom-right-radius: var(--sf-border-radius) !important;
    }

    .select2-selection__arrow {
        position: relative !important;
        margin-left: auto;
    }

    .select2-selection__rendered {
        padding-left: 0 !important;
    }

    .select2-selection__placeholder {
        color: var(--sf-form-control-placeholder-color) !important;
        font-weight: var(--sf-font-weight-normal);
    }

    .select2-selection__rendered {
        display: flex !important;
        align-items: center !important;
    }

    .select2-selection__clear {
        background-color: var(--sf-form-control-border-color);
        width: 24px;
        text-align: center;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        margin-right: 8px;
    }

    .select2-dropdown {
        border-radius: var(--sf-border-radius) !important;
        box-shadow: var(--sf-block-shadow), 0 0 0 10000px var(--sf-color-darken-a10) !important;
        border-color: var(--sf-form-control-focus-border-color);
        border: 2px solid var(--sf-form-control-focus-border-color) !important;
    }

    .select2-search--dropdown {
        padding: 8px !important;
    }

    .select2-search__field {
        @include formControl();
    }

    .select2-results__option {
        padding: 6px 10px !important;
    }

    .select2-results__option--highlighted {
        background-color: var(--sf-form-control-text-color) !important;
    }

    .select2-results__option.loading-results {
        position: relative;
        padding-inline-start: 40px !important;
        &:before {
            @include spinner(24px);
            position: absolute;
            inset-inline-start: 8px;
        }
    }
}