@use "sass:math";


@mixin spinner($size: 32px) {
    @keyframes select2-spin {
        to {
            transform: rotate(360deg);
        }
    }

    display: block;
    content: '';
    width: $size;
    height: $size;
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, .15);
    border-top-color: rgba(0, 0, 0, .65);
    animation: .6s km-spin infinite linear;
}