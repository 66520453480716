@import "../constants";

.formRow {
    width: 100%;
}

.formRow__labelCell {
    vertical-align: top;
    padding-top: .5rem;
    text-align: right;
    position: relative;
}

.formRow__label {
    font-weight: var(--sf-font-weight-normal);
    cursor: pointer;
    opacity: .85;
    margin-bottom: 0;
}

.formRow__label--required, .formRow__label.required {
    font-weight: var(--sf-font-weight-semibold);
    color: var(--sf-text-color);
}


.formRow__inputCell {
    padding-bottom: 4px;
}

.formRow--labelInside {
    .formRow__labelCell {
        height: 0;
        width: 100%;
        text-align: left;
        padding: 0;
        z-index: 10;
    }

    .formRow__label {
        padding-left: .5em;
        font-size: $font-size-088rem;
        font-weight: var(--sf-font-weight-semibold);
    }
}