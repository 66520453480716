@import "../mixins/svgiconMixins";

.noImage {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    .svgicon {
        @include svgicon-size(24px);
        @include svgicon-color(var(--sf-text-color));
        opacity: .25;
    }
}