@import "../constants";
@import "../mixins/headingMixins";

.searchHistory {

}

.searchHistory__heading {
    @include heading--h6;
    height: 32px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.searchHistory__items {
    margin-bottom: 8px;

    &.--example {
        display: flex;
        flex-wrap: wrap;
    }
}

.searchHistory__item {
    display: flex;
    align-items: center;
    height: 32px;
    font-weight: var(--sf-font-weight-semibold);
    position: relative;
    line-height: 1;
    font-size: .9rem;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--sf-line-color-light);
    }

    @media (hover) {
        &:hover {
            text-decoration: underline;
        }
    }

    &.--product {
        height: 48px;
    }

    &.--example {
        height: 24px;
        border: 1px solid var(--sf-line-color-light);
        border-radius: 12px;
        margin-bottom: 4px;
        margin-inline-end: 4px;
        padding-inline-start: 8px;
    }
}

.searchHistory__item__icon {
    margin-left: auto;
    transform: scale(.4);
}

.searchHistory__item__img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    display: inline-block;
    margin-right: 1rem;
    background-color: white;
    border-radius: 2px;
}

#searchHistory__clear {
    margin-top: 1rem;
}

#searchHistory__clearButton {
    appearance: none;
    border: none;
    background-color: transparent;
    color: var(--sf-text-light-color);
    cursor: pointer;
    padding: 0 0 1px;
    margin: 0;
    text-decoration: underline !important;
    text-underline-position: under;
    font-weight: var(--sf-font-weight-normal);
    font-size: .8rem;

    @media (hover) {
        &:hover, &.--active {
            color: var(--sf-text-color);
        }
    }
}