@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/imageMixins";
@import "../mixins/svgiconMixins";


.productDetailAddToCart__availability {
    margin: 16px 0;
}

#productDetailAddToCart__supplyOptionSelect {
    margin: 16px 0;
}

.productDetailAddToCart__supplyOptionNav {
    margin: 16px 0;
}

.productDetailAddToCart__supplyOptionNav__heading {
    margin-bottom: 8px;
    padding-inline: 12px;
}

.productDetailAddToCart__supplyOptionNav__list {
    display: grid;
    gap: 8px;
}

.productDetailAddToCart__supplyOption {
    list-style-type: none;
    appearance: none;
    padding: 4px 12px 4px 6px;
    border-radius: var(--sf-border-radius);
    display: flex;
    position: relative;

    &.--selective {
        @include blockButton--bg();
        cursor: pointer;

        &:not(:disabled) {
            @include blockButton--hoverable();
        }
    }

    &.--current {
        @include blockButton--current();
    }

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        pointer-events: none;
        border-radius: var(--sf-border-radius);
    }

    > * {
        position: relative;
    }

    &.--fastest:before {
        background-color: var(--sf-supply-option-fastest-bg-color) !important;
        opacity: 1 !important;
    }

    &.--cheapest:before {
        background-color: var(--sf-supply-option-cheapest-bg-color) !important;
        opacity: 1 !important;
    }

    &.--bgColor:before {
        opacity: .125;
    }

    &.--bgColor.--red:before {
        background-color: var(--sf-availability-label-red-text-color) !important;
    }

    &.--bgColor.--orange:before {
        background-color: var(--sf-availability-label-orange-text-color) !important;
    }

    &.--bgColor.--green:before {
        background-color: var(--sf-availability-label-green-text-color) !important;
    }

    &.--bgColor.--teal:before {
        background-color: var(--sf-availability-label-teal-text-color) !important;
    }

    &.--bgColor.--blue:before {
        background-color: var(--sf-availability-label-blue-text-color) !important;
    }

    &.--bgColor.--grey:before {
        background-color: var(--sf-text-color) !important;
    }

    &:disabled {
        opacity: .5;
        cursor: not-allowed !important;
        color: var(--sf-text-color);
    }
}

.productDetailAddToCart__supplyOption__tags {
    margin-bottom: 4px;
}

.productDetailAddToCart__supplyOption__picture {
    width: 48px;
    height: 48px;
    border-radius: var(--sf-border-radius);
    overflow: hidden;
    //@include imageOverlay();
    margin-right: 12px;
    background-color: white;
}

.productDetailAddToCart__supplyOption__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.productDetailAddToCart__supplyOption__details {
    align-self: center;
    flex-grow: 1;
    text-align: start;
    position: relative; // to be above .productDetailAddToCart__supplyOption
}


.productDetailAddToCart__supplyOption__name {
    margin-bottom: 8px;
}

.productDetailAddToCart__supplyOption__availabilityAndPrices {
    display: flex;
    justify-content: space-between;
}

.productDetailAddToCart__supplyOption__userPrice {
    font-weight: var(--sf-font-weight-bold);
    font-size: var(--sf-font-size-large);
}

.productDetailAddToCart__supplyOption__deliveryDateTime {
    margin-left: 36px;
    font-size: $font-size-088rem;
}

.productDetailAddToCart__supplyOption__deliveryDateTime__deadline {
    color: var(--sf-text-light-color);
}

.productDetailAddToCart__prices {
    margin: 16px 0;
}

#productDetailAddToCart__form {
    display: flex;
}

.productDetailAddToCart__form__count {
    width: 100px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    span:first-child {
        margin-inline-end: 12px;
    }
}

.productDetailAddToCart__form__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 192px;
    flex-grow: 1;
    max-width: 256px;
    padding: 0 1rem;

    .svgicon {
        @include svgicon-size(20px);
        margin: 0 .5rem;
    }
}