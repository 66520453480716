@import "../constants";
@import "../mixins/svgiconMixins";

.productAvailabilityShort {
    color: $color-red;
    display: inline-flex;
    align-items: flex-start;
    font-size: $font-size-088rem;
    line-height: 16px;
    position: relative;

    &.--ableToOrder {
        color: var(--sf-availability-label-able-to-order-text-color);
    }

    &.--available {
        color: var(--sf-availability-label-available-text-color);
    }

    &.--onRequest {
        color: var(--sf-availability-label-on-request-text-color);
    }

    &.--red {
        color: var(--sf-availability-label-red-text-color) !important;
    }

    &.--orange {
        color: var(--sf-availability-label-orange-text-color) !important;
    }

    &.--green {
        color: var(--sf-availability-label-green-text-color) !important;
    }

    &.--teal {
        color: var(--sf-availability-label-teal-text-color) !important;
    }

    &.--blue {
        color: var(--sf-availability-label-blue-text-color) !important;
    }

    &.--grey {
        color: var(--sf-text-color) !important;
    }

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        pointer-events: none;
        border-radius: var(--sf-border-radius);
    }

    > * {
        position: relative;
    }

    &.--bgColor {
        padding: 4px 6px;
        &:before {
            background-color: currentColor;
            opacity: .125;
        }
    }
}

.productAvailabilityShort__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-inline-end: 6px;

    picture {
        display: contents;
    }

    img {
        flex-shrink: 0;
        object-fit: contain;
        width: 32px;
        height: 32px;
    }

    .svgicon {
        flex-shrink: 0;
        @include svgicon-size(16px);
        @include svgicon-color(currentColor);
    }
}

.productAvailabilityShort__label {
    strong, b {
        font-weight: var(--sf-font-weight-bold);
    }
}

.productAvailabilityShort__subLabel {
    font-weight: var(--sf-font-weight-normal);
    color: var(--sf-availability-label-sub-label-text-color);
    strong, b {
        font-weight: var(--sf-font-weight-bold);
    }
}