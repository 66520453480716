@import "../constants";
@import "../mixins/svgiconMixins";


.pageMessage,
.kvikymart-no-message {
    text-align: var(--sf-page-message-text-align);
    margin-block: 32px;

    &.--withPane {
        padding: 32px;
        background-color: var(--sf-pane-bg-color);
        border-radius: var(--sf-border-radius);
    }

    &.--withIcon {
        display: flex;
        align-items: center;
        line-height: 2;
    }
    &.--center {
        text-align: center;
    }
}

.pageMessage__icon {
    .svgicon {
        width: 48px;
        height: 48px;
    }

    margin-inline-end: 16px;
    vertical-align: middle;
}