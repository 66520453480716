@import "../constants";
@import "../mixins/formMixins";
@import "../mixins/verticalMarginMixins";
@import "../mixins/buttonLinkMixins";
@import "../mixins/headingMixins";

@mixin km-form--labelsInside() {
    table, tbody, tr, th, td {
        display: block;
        margin-inline-start: 0;
        width: 100%;
    }
    th {
        height: 0;
        width: 100%;
        text-align: left;
        padding: 0;
        z-index: 10;

        &:dir(rtl) {
            text-align: right;
        }
    }
    label, .kvikymart-form-html-control-label {
        padding-inline-start: 8px;
        font-size: $font-size-088rem;
        font-weight: var(--sf-font-weight-semibold);
    }
    input[type=text], input[type=email], input[type=tel], input[type=number], input[type=password], select, textarea, #frm-userProfile-userEditAccount-editAddressesForm-editContactForm-loginEmail {
        @include formControl--labelInside;
    }
    input[type=submit] {
        margin-top: 8px;
    }
}

.km-form {
    user-select: none;
    display: contents;
    @include verticalMarginBottom;

    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0;
    }

    .km-form__fieldset--sticky {
        position: sticky;
        bottom: 0;
        background-color: var(--sf-body-bg-color);
        padding-bottom: 8px;
        margin-top: 8px;
        border-top: 1px solid var(--sf-line-color-strong);
        //box-shadow: var(--sf-block-shadow);
        clip-path: inset(-100px 0 0 0);
        z-index: 10;
    }

    legend, h2 {
        @include heading;
        @include heading--h3;
        line-height: $line-height;
        margin-bottom: 0;
        border: none;
        white-space: nowrap;
        padding: 16px 0;
        user-select: none;

        &:empty {
            display: none;
        }
    }

    table {
        width: 100%;
    }

    th {
        font-weight: var(--sf-font-weight-normal);
        vertical-align: top;
        padding-top: 8px;
        text-align: right;
        position: relative;

        &:dir(rtl) {
            text-align: left;
        }
    }

    td {
        padding-bottom: 4px;
    }

    label, .kvikymart-form-html-control-label {
        font-weight: var(--sf-font-weight-normal);
        cursor: pointer;
        opacity: .85;
        margin-bottom: 0;

        strong {
            color: var(--sf-text-color);
            font-weight: var(--sf-font-weight-bold);
            margin: 0 4px;
        }
    }

    label.required {
        font-weight: var(--sf-font-weight-bold);
        color: var(--sf-text-color);

        &:after {
            content: '*';
            margin-inline-start: 4px;
            color: $color-red;
            font-weight: var(--sf-font-weight-bold);
        }
    }

    td > label, label.input-inside {
        display: inline-block;
        padding-left: 32px !important;
        position: relative;
        margin-bottom: 8px;

        input[type=checkbox],
        input[type=radio] {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    input[type=text], input[type=email], input[type=tel], input[type=number], input[type=password], select, textarea {
        @include formControl();
    }

    input[type=submit] {
        @include btnPrimary;
        @include btnExtraLarge;
        margin-top: 12px;
        width: 100%;
        display: flex;
    }

    input[type=radio],
    input[type=checkbox] {
        display: inline-block;
        margin-inline-end: 10px;
        transform: translateY(-2px);
    }

    input[type=number] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }
    }

    select {
        appearance: none;
        @include formControl--select();
    }

    textarea {
        line-height: $line-height-120;
        padding-top: 8px;
        padding-bottom: 8px;
        min-height: 112px !important;
        resize: vertical;
        max-height: 240px;
    }

    .error {
        @include formError();
    }

    @media (max-width: $screen-600px-1) {
        @include km-form--labelsInside();
    }
    @media (min-width: $screen-600px) {
        th {
            width: 25%;
            padding-inline-end: 16px;
        }

        td {
            padding-inline-end: 12.5%;
        }

        legend, h2 {
            padding-inline-start: 25%;
        }
    }
    @media (min-width: $screen-900px) {
        td {
            padding-inline-end: 25%;
        }
    }
}

.phoneNumberFormControl {
    display: flex;
    justify-content: space-between;
}

.phoneNumberFormControl__cell--countryPrefix {
    flex-basis: 10em !important;
}

.km-form--contactForm {
    @include km-form--labelsInside();
    margin: 0;
    //max-width: 80ch;

    legend, h2 {
        padding-inline-start: 0;
        padding-top: 0;
    }

    td {
        padding-inline-end: 0 !important;
    }
}

.kvikymart-stylize-text {
    @include verticalMarginTop;
    @include verticalMarginBottom;

    ul li {
        display: block;
    }

    @media (hover) {
        & a:hover {
            text-decoration: underline;
        }
    }
}

.km-form tr:last-of-type .kvikymart-stylize-text {
    margin-bottom: 0 !important;
}

.km-loginControl {
    margin-top: 32px;
}

.km-loginControl,
.km-newsletterSubscriberControl {
    .km-form {
        margin-bottom: 0;
    }
}