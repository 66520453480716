@import "../constants";
@import "../mixins/formMixins";

.layoutHeaderSearch {
    position: relative;
    width: 100%;

    * {
        vertical-align: top;
    }

    height: $layoutHeaderMainBar-default-height;
    @media (min-width: $screen-760px) {
        height: var(--sf-layout-header-search-height);
    }
}

.layoutHeaderSearch__input {
    @include formControl();

    &::-ms-clear,
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    &::placeholder {
        color: currentColor;
        opacity: .65;
        font-weight: var(--sf-font-weight-normal) !important;

        @media (min-width: $screen-1200px) {
            text-align: var(--sf-layout-header-search-placeholder-text-align);
        }
    }

    width: 100%;
    user-select: text;
    font-size: 0.9rem;
    position: relative;
    height: 100%;
    line-height: $layoutHeaderMainBar-default-height;
    border-radius: var(--sf-layout-header-search-border-radius) !important;
    -webkit-appearance: none;
    padding-right: $layoutHeaderMainBar-default-height;

    @media (max-width: $screen-760px-1) {
        background: var(--sf-layout-header-search-mobile-bg-color) none;
        border: 1px solid var(--sf-layout-header-search-mobile-border-color);
        color: var(--sf-layout-header-search-mobile-text-color);
    }

    @media (min-width: $screen-760px) {
        background: var(--sf-layout-header-search-bg-color) none;
        background-clip: var(--sf-layout-header-search-bg-clip);
        color: var(--sf-layout-header-search-bg-color-contrast);
        box-shadow: var(--sf-layout-header-search-box-shadow);
        border: 1px solid var(--sf-layout-header-search-border-color);
        padding-right: 56px;

        &:focus {
            color: var(--sf-layout-header-search-bg-focus-color-contrast) !important;
            background-color: var(--sf-layout-header-search-bg-focus-color) !important;
            border: 1px solid var(--sf-layout-header-search-border-focus-color) !important;
            box-shadow: var(--sf-layout-header-search-focus-box-shadow) !important;
        }
    }

    @media (min-width: $screen-1200px) {
        font-size: 1rem;
    }
}

.layoutHeaderSearch__button {
    border: none;
    vertical-align: middle;
    width: var(--sf-layout-header-search-height);
    background: transparent none !important;
    box-shadow: none;
    border-radius: 0 var(--sf-border-radius) var(--sf-border-radius) 0;
    right: 0;
    left: auto;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    transition: opacity .5s $transition-timing-function;
    color: var(--sf-form-control-text-color);
    height: 100%;

    .svgicon {
        opacity: .5;
        transform: scale(.75);
    }

    @media (hover) {
        &:hover, &:focus, &.focus {
            .svgicon {
                opacity: 1;
            }
        }
    }

    @media (min-width: $screen-760px) {
        color: var(--sf-layout-header-search-bg-color-contrast);
        .layoutHeaderSearch__input:focus + & {
            color: var(--sf-layout-header-search-bg-focus-color-contrast);
        }
    }

    @media (min-width: $screen-900px) {
        width: 56px;
        .svgicon {
            transform: scale(1);
        }
    }
}