@import "../constants";
@import '../../node_modules/swiper/swiper';
@import '../../node_modules/swiper/modules/navigation';
@import '../../node_modules/swiper/modules/pagination';
@import '../../node_modules/swiper/modules/scrollbar';
@import '../../node_modules/swiper/modules/autoplay';

.swiper {
    --swiper-scrollbar-sides-offset: 2px; /* `px` unit needed, otherwise scrollbar isn't shown */
    --swiper-scrollbar-bg-color: rgba(0, 0, 0, .05); //var(--sf-line-color-light);
    --swiper-scrollbar-drag-bg-color: var(--sf-line-color-strong);
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: var(--ksfdef-parent-text-color);
    --swiper-pagination-bullet-horizontal-gap: 2px;
    --swiper-navigation-sides-offset: calc(-1 * var(--sf-border-radius));
    overflow: unset;
    user-select: none;
    @container pagePartContainer (width >= #{$screen-desktop}) {
        padding: 1px;
    }
}

.swiper-slide {
    width: auto;
    display: flex;
    align-self: stretch;
    height: auto;
}

.swiper-scrollbar {
    transition: opacity $transition-duration;
    opacity: 0;
    bottom: 0 !important;

    @media (hover) {
        .swiper:hover & {
            opacity: 1;
        }
    }
}

.swiper-pagination {
    padding: 0 16px;
    transform: translateY(16px);
    display: flex;
    justify-content: center;
    position: absolute;
}

.swiper-pagination-bullet {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: var(--sf-text-color);
    opacity: .1;
    display: inline-block;
    height: 4px;
    flex-basis: 1.5rem;
    //flex-grow: 1;
    margin: 0 (4px * 0.5);
    border-radius: 2px;
    @media (min-width: $screen-600px) {
        flex-basis: 32px;
    }
    @media (min-width: $screen-1200px) {
        flex-basis: 48px;
    }
    transition: opacity .35s $transition-timing-function;

    @media (hover) {
        &:hover {
            opacity: .5;
            box-shadow: var(--sf-block-shadow);
        }
    }
}

.swiper-pagination-bullet-active {
    opacity: 1 !important;
}

.swiper-button-prev,
.swiper-button-next {
    background-color: var(--ksfdef-parent-bg-color);
    background-clip: content-box;
    width: 44px;
    height: calc(min(100px, 100%));
    top: 50%;
    transform: translateY(-50%);
    border-radius: var(--sf-border-radius);
    transition: opacity $transition-duration;
    pointer-events: none;
    opacity: 0 !important;
    margin-top: 0;

    @media (hover) {
        .swiper:hover &,
        .swiper &:hover {
            opacity: 1 !important;
            pointer-events: auto;
        }

        .swiper &.swiper-button-disabled {
            opacity: 0 !important;
            cursor: not-allowed;
            pointer-events: auto;
        }
    }
}

.swiper.--productCollection {
    .swiper-button-prev,
    .swiper-button-next {
        &:not(.swiper-button-disabled) {
            @media (min-width: $screen-desktop) {
                opacity: 1 !important;
            }
        }

        @media (max-width: $screen-desktop-1) {
            box-shadow: var(--sf-block-shadow);
            border: 1px solid var(--sf-line-color-100);
        }
    }

    --swiper-product-collection-flex-basis: var(--ksfdef-layout-default-column-width);
    @media (min-width: $screen-desktop) {
        --swiper-product-collection-flex-basis: calc(var(--layout-main-column-width, var(--ksfdef-layout-default-column-width)) - var(--sf-grid-nav-gap));
    }

    &.--size-extraSmall {
        --swiper-product-collection-flex-basis: 100%;
        @media (min-width: $screen-760px) {
            --swiper-product-collection-flex-basis: calc(var(--layout-main-column-lg-width, 375px) - var(--sf-grid-nav-gap));
        }
    }

    .swiper-slide {
        flex-basis: var(--swiper-product-collection-flex-basis);
    }
}

.swiper.--imageSlider {
    padding: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.25s ease-out;

    &.--loaded {
        opacity: 1;
    }

    .swiper-slide {
        width: 100%;
        max-height: $screen-tablet-1;
        filter: var(--sf-page-part-image-slider-slide-filter);
        transform: var(--sf-page-part-image-slider-slide-transform);
        opacity: var(--sf-page-part-image-slider-slide-opacity);
        transition-property: filter, transform, opacity;
        transition-duration: var(--sf-page-part-image-slider-slide-transition-duration);
        border-radius: var(--sf-border-radius);
    }

    .swiper-slide-active {
        filter: blur(0px) !important;
        transform: none !important;
        opacity: 1 !important;
    }

    img {
        display: block;
        object-fit: fill;
        height: auto;
        width: 100%;
        border-radius: var(--sf-border-radius);
    }
}

.swiper {
    &.--iconImageSlider, &.--imageGallery {
        .swiper-slide {
            align-items: center;
            background-color: white;
            border-radius: var(--sf-border-radius);
        }

        picture {
            display: flex;
            align-items: stretch;
            justify-content: stretch;
            box-sizing: content-box; // because of padding-block and max-height set in pagePart
        }

        img {
            width: auto;
            aspect-ratio: 1/1;
            border-radius: var(--sf-border-radius);
            display: block;
            object-fit: contain;
            object-position: center center;
        }
    }

    &.--iconImageSlider {
        img {
            max-height: calc(128px - 16px);
            aspect-ratio: auto;
        }

    }

    &.--imageGallery {
        .swiper-slide {
            width: var(--layout-main-column-width);
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1 / 1;
        }

        img {
            height: auto;
            max-width: 80%;
            max-height: 80%;
        }

        picture {
            justify-content: center;
            align-items: center;
        }
    }
}