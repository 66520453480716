@import "../constants";

.imagePagePart {
    user-select: none;
    display: block;
    overflow: hidden;
    border-radius: var(--sf-border-radius);
}

.imagePagePart--center {
    text-align: center;
    img {
        margin: 0 auto;
    }
}

.imagePagePart__image {
    -webkit-user-drag: none;
}