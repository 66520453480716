@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/buttonLinkMixins";

.btnDefault {
    @include btnDefault;
}

.btnPrimary {
    @include btnPrimary;
}

.btnLinkUnderlinedSubtleLine {
    @include btnLinkUnderlinedSubtleLine;
}

.linkUnderlineOnHover {
    @include linkUnderlineOnHover;
}

.linkUnderlined {
    @include linkUnderlined;
}

.--btnExtraSmall {
    @include btnExtraSmall;
}

.--btnSmall {
    @include btnSmall;
}

.--btnLarge {
    @include btnLarge;
}

.--btnExtraLarge {
    @include btnExtraLarge;
}