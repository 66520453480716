@import "../constants";

@keyframes effectShakeAnimation {
    0% {
        transform: translate(20px);
    }
    20% {
        transform: translate(-20px);
    }
    40% {
        transform: translate(10px);
    }
    60% {
        transform: translate(-10px);
    }
    80% {
        transform: translate(5px);
    }
    100% {
        transform: translate(0px);
    }
}


.--effectShake {
    animation: effectShakeAnimation .4s 1 linear !important;
}