@import "../constants";


.dropdown {
    contain: layout style;
    z-index: var(--ksfdef-z-index-dropdown);
    display: none;
    position: absolute;
    &.--active {
        display: block;
    }

    //&[data-popper-reference-hidden] {
    //    display: none !important;
    //}
}

.dropdown__content {
    opacity: 0;
    transition-property: opacity, transform;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration;
    background-color: var(--sf-modal-bg-color);
    border-radius: var(--sf-border-radius);
    box-shadow: var(--sf-modal-box-shadow);
    //box-shadow: 0 0 200px 100px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1), 0 0 0 1px rgba(0, 0, 0, .15);
    transform-origin: center top;
    transform: scaleY(.85);
    //transform: scale(.9);
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;

    &.--active {
        opacity: 1;
        transform: scale(1);
    }

    .dropdown[data-popper-placement="bottom-end"] & {
        //transform-origin: right top;
    }

    @media (max-width: $screen-760px-1) {
        width: 100vw !important;
        border-radius: 0 !important;
        //padding-bottom: 128px;
    }
}