@import "../constants";
@import "../mixins/containerMixins";

.containerLayout {
    @include container();
}

.containerInnerNotMobile {
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 12px;
    padding-right: 12px;
    @media (min-width: $screen-600px) {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
    @media (min-width: $screen-900px) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }
}

.containerNarrow {
    @media (min-width: $screen-1200px) {
        margin-left: 12.5%;
        margin-right: 12.5%;
    }
}

.mobileAppHeader {
    & ~ .containerLayout {
        padding-top: 36px;
    }
}
