@import "../constants";

.displayOnScreen {
    display: none;

    &.--displayMobile {
        @media (max-width: $screen-tablet-1) {
            display: block;
        }
    }

    &.--displayTablet {
        @media (min-width: $screen-tablet) and (max-width: $screen-desktop-1) {
            display: block;
        }
    }

    &.--displayDesktop {
        @media (min-width: $screen-desktop) {
            display: block;
        }
    }
}