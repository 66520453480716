@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/buttonLinkMixins";

.filterVehicle {
    margin: 24px 0;
    border-radius: var(--sf-border-radius);
    align-items: flex-start;
    display: block;
    background-color: var(--sf-page-part-vehicle-search-background-color);
    color: var(--sf-page-part-vehicle-search-text-color);
    //box-shadow: 0 0 0 1px var(--sf-line-color-light), var(--sf-block-shadow);
    //border: 1px solid var(--sf-line-color-light);
    padding: 12px 16px;
    @media (min-width: $screen-900px) {
        display: flex;
    }
}

.filterVehicle__prompt {
    flex-grow: 1;
    font-weight: var(--sf-font-weight-semibold);
    font-size: 1.1em;
    display: flex;
    align-items: center;
    align-self: center;

    .svgicon {
        opacity: .5;
        margin-inline-end: 16px;
        @include svgicon-size(32px);
        transform: translateY(-2px);
    }
}

.filterVehicle__vehicleSelected {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.filterVehicle__vehicleSelectedTitle {
    margin-top: 8px;
    color: var(--sf-text-light-color);
    font-size: .9em;
}

.filterVehicle__vehicle {
    display: flex;
    background-color: white;
    align-items: center;
    padding: 2px 12px;
    min-height: var(--sf-btn-height);
    border-radius: var(--sf-border-radius);

    @media (hover) {
        &:hover {
            box-shadow: 0 0 0 1px var(--sf-line-color-light), var(--sf-block-shadow);
        }
    }

    picture {
        display: contents;
    }
}

.filterVehicle__vehicle__logo {
    height: 32px;
    width: 32px;
    object-fit: contain;
    flex-grow: 0;
}

.filterVehicle__vehicle__details {
    flex-grow: 1;
    padding: 0 24px;
}

.filterVehicle__vehicle__details__power, .filterVehicle__vehicle__details__years {
    font-size: .9em;
    color: var(--sf-text-color);
    padding-inline-start: 4px;

    &:before {
        content: '•';
        padding-inline-end: 4px;
    }
}

.filterVehicle__vehicle__image {
    height: 48px;
    max-width: 150px;
    min-width: 100px;
    object-fit: contain;
    margin-inline-start: auto;
    flex: 0 0;
}

.filterVehicle__buttons {
    display: grid;
    gap: 8px;
    flex: 0 0 25%;
    margin-top: 16px;
    @media (min-width: $screen-900px) {
        margin-top: 0;
        margin-inline-start: 16px;
    }
}

.filterVehicle__btnDeselect {
    @include btnLinkUnderlinedLightTextColor();
    font-size: $font-size-088rem;
    margin-inline: auto;

    .svgicon {
        @include svgicon-size(16px);
    }
}