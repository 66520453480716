@use "sass:math";

@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/clearfixMixin";
@import "../mixins/arrowMixins";
@import "../mixins/buttonLinkMixins";

$vehicleCategoryNav__name-height: 36px;
$vehicleCategoryNav__link--1-height: 48px;
$vehicleCategoryNav__name--1-height: 2.75rem;
$vehicleCategoryNav__name-padding: 6px;
$vehicleCategoryNav__opener-width: 32px;
$vehicleCategoryNav-separator-border: 1px solid var(--sf-line-color-light);

.vehicleCategoryNav {
    width: 100%;
}

.vehicleCategoryNav__partsByCar {
    padding: 2px 0;
    border-bottom: $vehicleCategoryNav-separator-border;
    display: none;
    @media (min-width: $screen-1200px) {
        display: block;
    }
}

.vehicleCategoryNav__partsByCarBtn {
    display: flex;
    padding-inline-start: $vehicleCategoryNav__opener-width;
    justify-content: flex-start;
    align-items: center;
    font-weight: var(--sf-font-weight-bold);

    .svgicon {
        margin-inline-end: .5rem;
        position: relative;
        //top: 4px;
        opacity: .3;
    }
}

.vehicleCategoryNav {
    user-select: none;
    @include clearfix;

    &--showOnlyInSidebar {
        display: none;
        @media (min-width: $screen-1200px) {
            display: block;
        }
    }
}

.vehicleCategoryNav__list {
    display: flex;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    flex-wrap: wrap;

    &--root {
        height: auto;
        display: flex;
        overflow: visible;
        border-top: none;
    }

    &--0 {
        .vehicleCategoryNav__list--1 {
            .vehicleCategoryNav__link--1 {
                height: $vehicleCategoryNav__name-height !important;
            }
        }
    }

    &--inCurrentPath, &--childOfCurrent {
        height: auto;
        overflow: visible;
    }

    @media (min-width: $screen-1200px) {
        &.js-in {
            border-top: $vehicleCategoryNav-separator-border;
            height: auto;
        }
    }
}

.vehicleCategoryNav__node {
    position: relative;
    display: block;
    flex-basis: 100%;

    &--0 {
        margin-top: $vehicleCategoryNav__name-padding * 0.5;
        padding-bottom: $vehicleCategoryNav__name-padding * 0.5;
    }
}

.vehicleCategoryNav__node--importantRoot {
    border-bottom: $vehicleCategoryNav-separator-border;

    &.vehicleCategoryNav__node--root {
        .vehicleCategoryNav__opener {
            display: none !important;
        }

        @media (min-width: $screen-600px) {
            flex-basis: 50%;
            max-width: 50%;
            &:nth-child(odd) {
                padding-inline-end: 4px;
            }
        }
    }

    @media (min-width: $screen-900px) {
        .vehicleCategoryNav__list {
            width: 200%;
            position: relative;

            &--3 {
                height: auto;
                width: 100%;

                &:dir(ltr) {
                    left: 0 !important;
                }

                &:dir(rtl) {
                    right: 0 !important;
                }
            }

            &.js-in {
                margin-bottom: $vehicleCategoryNav__name--1-height;
                overflow: visible;
                z-index: 10;
                border-top: 4px solid var(--sf-line-color-strong);
                @include arrow-top(10px, var(--sf-line-color-light), calc(#{$vehicleCategoryNav__opener-width} + 1rem));
            }
        }

        .vehicleCategoryNav__list--loaded {
            //height: auto;
            //left: 0;
            //width: 100%;
        }

        .vehicleCategoryNav__node {
            flex-basis: 50%;
        }

        &:nth-child(even) {
            .vehicleCategoryNav__list {
                &:dir(ltr) {
                    left: -100%;
                }

                &:dir(rtl) {
                    right: -100%;
                }

                &.js-in {
                    @include arrow-top(8px, var(--sf-line-color-light), unquote("calc(50% + #{$vehicleCategoryNav__opener-width} + 1em)"));
                }
            }
        }
    }
}

.vehicleCategoryNav__node--importantChilds {
    display: none;
    @media (min-width: $screen-1200px) {
        display: block;
        border-bottom: $vehicleCategoryNav-separator-border;
        margin-inline-start: $vehicleCategoryNav__opener-width;
        &:last-of-type {
            border-bottom: none;
        }

        &.vehicleCategoryNav__node--root, &.vehicleCategoryNav__node--2 {
            margin-inline-start: 0;
        }

        &.vehicleCategoryNav__node--root {
            border-bottom: $vehicleCategoryNav-separator-border;
        }
    }
}

.vehicleCategoryNav__node--1.vehicleCategoryNav__node--inCurrentPath {
    @media (min-width: $screen-1200px) {
        border-bottom: none !important;
        background-color: var(--sf-block-button-bg-color);
        border-radius: var(--sf-border-radius);
        box-shadow: var(--sf-block-shadow);
        order: -1;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
}

.vehicleCategoryNav__link {
    display: block;
    border-inline-end: none;
    position: relative;
    border-radius: var(--sf-border-radius);
    text-decoration: none !important;

    &--1 {
        height: $vehicleCategoryNav__link--1-height;
    }

    @media (min-width: $screen-1200px) {
        &--current {
            @include arrow-inline-end(9px, var(--sf-block-button-highlighted-bg-color));

            &.vehicleCategoryNav__link--1 {
                @include arrow-inline-end(16px, var(--sf-block-button-highlighted-bg-color));
            }
        }
    }
}

.vehicleCategoryNav__name {
    user-select: text;
    line-height: $vehicleCategoryNav__name-height - 4px;
    border-radius: var(--sf-border-radius);
    display: flex;
    align-items: center;
    //padding: $vehicleCategoryNav__name-padding / 2;
    //margin: 2px 0;
    transition: color .25s, background-color .25s, box-shadow .25s, border-color .25s;
    color: var(--sf-color-link);
    padding-inline-start: $vehicleCategoryNav__name-padding !important;
    padding-inline-end: $vehicleCategoryNav__opener-width !important;
    word-break: break-word;
    @include blockButton();
    @include blockButton--hoverable();

    &--1 {
        line-height: 1;
        height: 100%;
        color: var(--sf-sidebar-nav-link-text-color);
    }

    @media (max-width: $screen-1200px-1) {
        font-weight: var(--sf-font-weight-semibold);

        &--importantChilds {
            font-size: 1rem;
        }
    }
    @media (min-width: $screen-1200px) {
        font-weight: var(--sf-font-weight-normal);
        font-size: $font-size-088rem;
        color: var(--sf-text-color);
        letter-spacing: 0;

        &--1 {
            font-weight: var(--sf-font-weight-semibold);
            font-size: 1rem;
            color: var(--sf-sidebar-nav-link-text-color);
        }

        &--2 {
            padding-inline-start: $vehicleCategoryNav__name-padding * 2 !important;
            font-weight: var(--sf-font-weight-semibold);
            color: var(--sf-text-color);
        }

        &--current {
            color: var(--sf-block-button-highlighted-text-color) !important;
            background-color: var(--sf-block-button-highlighted-bg-color) !important;
            box-shadow: 0 0 4px var(--sf-block-button-highlighted-bg-color);
        }

        &--inCurrentPath.vehicleCategoryNav__name--1 {
            background-color: var(--sf-line-color-strong);
            color: white;
        }
    }

    &--0 {
        @include btnDefault;
        line-height: var(--sf-btn-height);
        display: block;
        font-weight: var(--sf-font-weight-semibold);
        font-size: 1rem;
        @media (min-width: $screen-1200px) {
            text-align: left;

            &:dir(rtl) {
                text-align: right;
            }
        }
    }
}

.vehicleCategoryNav__opener {
    position: absolute;
    border: none;
    background-color: transparent;
    top: 0;
    color: rgba(black, math.div(20%, 100.0%));
    text-align: center;
    cursor: pointer;
    border-radius: var(--sf-border-radius);
    width: $vehicleCategoryNav__opener-width;
    height: 8 * 4px;
    line-height: 8 * 4px;
    font-size: 1.2rem;
    display: none;

    &:dir(ltr) {
        right: 0;
    }

    &:dir(rtl) {
        left: 0;
    }

    &--inCurrentPath {
        display: none !important;
    }

    &--1 {
        top: 0;
        height: $vehicleCategoryNav__link--1-height;
        line-height: $vehicleCategoryNav__link--1-height;
    }

    &--0 {
        z-index: 1000;
        height: 2.5rem;
    }

    .vehicleCategoryNav__openerMinus {
        display: none;
    }

    .vehicleCategoryNav__openerPlus {
        display: block;
    }

    @media (min-width: $screen-1200px) {
        &.js-initialized {
            display: block;
        }

        &.js-in {
            //background-color: $color-red;
            //color: white;
            .vehicleCategoryNav__openerMinus {
                display: block;
            }

            .vehicleCategoryNav__openerPlus {
                display: none;
            }
        }
    }
    @include blockButton();
    @include blockButton--hoverable();
}

.vehicleCategoryNav__iconImage {
    display: none;
}

.vehicleCategoryNav__nameSuffix {
    display: none;

    &--1 {
        display: block;
        bottom: 1px;
        left: 1px;
        position: absolute;
        font-size: 11px;
        color: var(--sf-text-color);
        letter-spacing: -0.5px;
    }
}