@import "../constants";
@import "../mixins/buttonLinkMixins";

.productFilter {
    overflow: hidden;
}

.productFilter__overview {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--sf-line-color-100);
    @media (min-width: $screen-900px) {
        border: 1px solid var(--sf-line-color-100);
        padding: 8px;
        border-radius: var(--sf-border-radius);
        overflow: hidden;
    }
}

.productFilter__overview__inner {
    display: flex;
    gap: 12px;
}

.productFilter__overview__btnOpen {
    @include btnPrimary();
    min-width: var(--sf-btn-min-width);
    flex-shrink: 0;
}

.productFilter__overview__btnReset {
    @include btnLinkUnderlinedLightTextColor();
    margin-inline-start: auto !important;
    flex-shrink: 0;
    font-size: $font-size-088rem;
}

.productFilter__overview__fieldFilters {
    display: flex;
    align-items: center;
    height: var(--sf-btn-height);
    font-size: $font-size-088rem;
    gap: 16px;
}

.productFilter__overview__fieldFilters__type {
    margin-inline-end: 4px;
    font-weight: var(--sf-font-weight-bold);
}

.productFilter__overview__fieldFilters__values {
    font-weight: var(--sf-font-weight-semibold);
    color: var(--sf-text-light-color);
}

.productFilter__buttons {
    padding-top: 16px;
    display: flex;
    gap: 24px;
}

.productFilter__buttons__btnSubmit {
    @include btnPrimary();
    flex-basis: var(--sf-btn-min-width);
}

.productFilter__buttons__btnReset {
    @include btnLinkUnderlinedLightTextColor();
    //flex-basis: var(--sf-btn-min-width);
}

.productFilter__filter {
    border-bottom: 1px solid var(--sf-line-color-100);
    padding-block: 16px;
}

.productFilter__filter__heading {
    margin-bottom: 8px;
}

.productFilter__filter__filterFields {
    @media (min-width: $screen-520px) {
        &.--productType {
            column-count: 2;
        }
        &.--brand {
            column-count: 3;
        }
    }
}

.productFilter__filterField {
    display: block;
    padding-bottom: 6px;
}

.productFilter__filterField__link {
    display: inline-flex;
    align-items: flex-start !important;
    gap: 8px;
    font-weight: var(--sf-font-weight-semibold) !important;
    font-size: $font-size-088rem !important;
}

span.productFilter__filterField__link {
    color: var(--sf-text-light-color);
}

a.productFilter__filterField__link {
    @include linkUnderlineOnHover();
}

.productFilter__filterField__link__name {
    padding-top: 2px;
    display: block;
}

.productFilter__filterField__link__count {
    opacity: .5;
    font-weight: var(--sf-font-weight-normal);
}