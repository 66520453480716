@import "../constants";


.imageGridPagePart__wrapper {
    user-select: none;
    gap: 8px;
    display: flex;

    &.--align-center {
        justify-content: center;
    }

    &.--align-spaceBetween {
        justify-content: space-between;
    }

    &.--align-spaceAround {
        justify-content: space-around;
    }

    &.--displayType-wrap {
        flex-wrap: wrap;
    }

    &.--displayType-block {
        display: grid;
    }
}

.imageGridPagePart__item {

}

.imageGridPagePart__imageWrapper {
    display: block;
    overflow: hidden;
    border-radius: var(--sf-border-radius);
}

.imageGridPagePart__image {
    -webkit-user-drag: none;
    display: block;
    max-width: 100%;
    height: auto;
    //width: auto;
}

.imageGridPagePart__picture {
    display: block;
}