@import "../constants";
@import "../mixins/svgiconMixins";

@mixin btnLinkReset {
    margin: 0;
    padding: 0;
    border: none;
    appearance: none;
    cursor: pointer;
    font-size: inherit;
    font-weight: inherit;
    background-image: none;
    background-color: transparent;
    line-height: inherit;
    height: auto;
    box-shadow: none;
    border-radius: 0;
    vertical-align: middle;
    display: inline-flex;
    touch-action: manipulation;
    align-items: center;
    @media (hover) {
        &:active, &.active {
            outline: 0;
        }
    }
    &.disabled, &[disabled], fieldset[disabled] & {
        cursor: not-allowed !important;
        opacity: .65;
    }
    .svgicon {
        opacity: .5;
    }
}

@mixin btnReset {
    text-align: center;
    justify-content: center;
    line-height: 1.2;
    gap: 8px;
}

@mixin btnDefault {
    @include btnLinkReset;
    @include btnReset;
    text-align: center;
    align-items: center;
    border-radius: var(--sf-border-radius);
    font-size: 1rem;
    padding: 0 .5rem;
    height: var(--sf-btn-height);
    user-select: none;
    background-color: var(--sf-btn-default-bg-color);
    color: var(--sf-btn-default-text-color) !important;
    font-weight: var(--sf-font-weight-semibold);
    will-change: box-shadow, background-color, color;
    @media (hover) {
        &:not([disabled]):hover {
            background-color: var(--sf-btn-default-hover-bg-color);
            box-shadow: var(--sf-block-shadow);
            color: var(--sf-btn-default-hover-text-color) !important;
        }
    }
}

@mixin btnPrimary {
    @include btnLinkReset;
    @include btnReset;
    border-radius: var(--sf-border-radius);
    font-size: var(--sf-btn-primary-font-size);
    padding: 0 12px;
    height: var(--sf-btn-height);
    user-select: none;
    color: var(--sf-btn-primary-text-color) !important;
    font-weight: var(--sf-btn-primary-font-weight);
    font-style: var(--sf-btn-primary-font-style);
    text-transform: var(--sf-btn-primary-text-transform);
    letter-spacing: var(--sf-btn-primary-letter-spacing);
    word-spacing: var(--sf-btn-primary-word-spacing);
    background-color: var(--sf-btn-primary-bg-color);
    background-image: var(--sf-btn-primary-bg-image);
    background-size: var(--sf-btn-primary-bg-size);
    background-position: var(--sf-btn-primary-bg-position);
    background-repeat: var(--sf-btn-primary-bg-repeat);
    box-shadow: var(--sf-btn-primary-box-shadow);
    transition-property: box-shadow, color, background-color;
    transition-duration: $transition-duration;
    @media (hover) {
        &:not([disabled]):hover, {
            background-color: var(--sf-btn-primary-hover-bg-color);
            color: var(--sf-btn-primary-hover-text-color) !important;
            box-shadow: var(--sf-btn-primary-hover-box-shadow);
            //transform: translateY(-2px);
            //opacity: .75;
        }
    }
    &:not([disabled]):focus,
    &:not([disabled]).focus {
        background-color: var(--sf-btn-primary-hover-bg-color);
        color: var(--sf-btn-primary-hover-text-color) !important;
        box-shadow: var(--sf-btn-primary-hover-box-shadow);
        //transform: translateY(-2px);
        //opacity: .75;
    }
    @media (hover) {
        &:active, &.active {
            //transform: translateY(-2px);
            box-shadow: none;
        }
    }
    &[disabled] {
        background-color: var(--sf-line-color-light) !important;
        box-shadow: none !important;
        opacity: 1;
    }
    .svgicon {
        opacity: var(--sf-btn-primary-icon-opacity);
    }
}

@mixin btnExtraSmall {
    height: var(--sf-btn-xs-height);
    font-size: $font-size-088rem;
    gap: 4px;
    .svgicon {
        @include svgicon-size(12px);
    }
}

@mixin btnSmall {
    height: var(--sf-btn-sm-height);
    .svgicon {
        @include svgicon-size(16px);
    }
}

@mixin btnLarge {
    height: var(--sf-btn-lg-height);
    font-size: $font-size-110rem;
}

@mixin btnExtraLarge {
    height: var(--sf-btn-xlg-height);
    font-size: $font-size-110rem;
    @media (min-width: $screen-600px) {
        height: 56px;
    }
}

@mixin link($color: var(--sf-link-color)) {
    @include btnLinkReset;
    text-underline-offset: .2ex;
    outline: none;
    color: $color;
    //-webkit-tap-highlight-color: var(--sf-color-primary);
    text-decoration: none;
    letter-spacing: inherit;
    cursor: pointer !important;
    text-decoration-skip-ink: none;
    @media (hover) {
        &:not([disabled]):hover {
            color: var(--sf-link-hover-color);
            text-decoration: underline;
        }
    }
    &.disabled, &[disabled], fieldset[disabled] & {
        color: var(--sf-text-light-color);
    }
}

@mixin linkUnderlineOnHover($color: var(--sf-link-color)) {
    @include link($color);
    @media (hover) {
        &:hover {
            text-decoration: underline;
        }
    }
}

@mixin linkUnderlined($color: var(--sf-link-color)) {
    @include link($color);
    text-decoration: underline;
}

@mixin linkUnderlinedSubtleLine() {
    @include link();
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .15);
    text-decoration: none !important;
    @media (hover) {
        &:hover {
            color: var(--sf-link-hover-color);
            box-shadow: 0 1px 0 0 var(--sf-link-color);
        }
    }
}

@mixin btnLinkUnderlinedTextColor {
    @include linkUnderlined(var(--sf-text-color));
    @include btnReset();
    font-weight: var(--sf-font-weight-normal);
}

@mixin btnLinkUnderlinedLightTextColor {
    @include linkUnderlined(var(--sf-text-light-color));
    @include btnReset();
    font-weight: var(--sf-font-weight-normal);
    &[disabled] {
        color: var(--sf-text-light-color);
    }
}

@mixin btnLinkUnderlinedSubtleLine {
    @include linkUnderlinedSubtleLine;
    @include btnReset();
    font-weight: var(--sf-font-weight-semibold);
}