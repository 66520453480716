@import "../constants";
@import "../mixins/formMixins";

.formControl, input[type=number], input[type=text] {
    @include formControl;
}

.formControl--labelInside {
    @include formControl--labelInside;
}

.formControl--select, select.formControl {
    @include formControl--select;
}