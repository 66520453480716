// Screen sizes (DO NOT CHANGE)
$screen-320px: 320px;
$screen-360px: 375px;
$screen-400px: 400px;
$screen-400px-1: ($screen-400px - 1);
$screen-480px: 480px;
$screen-480px-1: ($screen-480px - 1);
$screen-520px: 520px;
$screen-520px-1: ($screen-520px - 1);
$screen-600px: 600px;
$screen-600px-1: ($screen-600px - 1);
$screen-760px: 760px;
$screen-760px-1: ($screen-760px - 1);
$screen-900px: 900px;
$screen-900px-1: ($screen-900px - 1);
$screen-1040px: 1040px;
$screen-1040px-1: ($screen-1040px - 1);
$screen-1200px: 1200px;
$screen-1200px-1: ($screen-1200px - 1);
$screen-1320px: 1320px;
$screen-1320px-1: ($screen-1320px - 1);
$screen-1400px: 1400px;
$screen-1400px-1: ($screen-1400px - 1);

$screen-tablet: $screen-760px;
$screen-tablet-1: $screen-760px-1;
$screen-desktop: $screen-1320px;
$screen-desktop-1: $screen-1320px-1;

// Transition (DO NOT CHANGE)
$transition-timing-function: cubic-bezier(.86, 0, .07, 1);
$transition-duration: .15s;

// Line Heights (DO NOT CHANGE)
$line-height: 1.5;
$line-height-150: 1.5;
$line-height-135: 1.35;
$line-height-120: 1.2;
$line-height-110: 1.1;

// Font Sizes (DO NOT CHANGE)
$font-size-075rem: 0.75rem;
$font-size-088rem: 0.88rem;
$font-size-100rem: 1rem;
$font-size-105rem: 1.05rem;
$font-size-110rem: 1.1rem;
$font-size-120rem: 1.2rem;

// Default Colors
$color-green: #59C736;
$color-red: #e30613;
$color-orange: #E89535;

// Layout Header
$layoutHeaderMainBar-default-height: 40px;
$layoutHeaderMainBar-default-padding: 8px;