@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/typeMixins";
@import "../mixins/svgiconMixins";
@import "../mixins/formMixins";


$shoppingCartMultipleCart-width: 240px;
$shoppingCartMultipleCart-formButton-width: 40px;

.shoppingCartMultipleCart {
    margin-top: -4px;
    background-color: var(--sf-body-bg-color);
    border-bottom: 4px solid var(--sf-line-color-light);
}

.shoppingCartMultipleCart__nav {
    display: flex;
    padding: 12px 1px 8px;
}

.shoppingCartMultipleCart__navCell {
    padding-inline-end: 8px;
    flex: 0;
    position: relative;
}

.shoppingCartMultipleCart__cart {
    width: $shoppingCartMultipleCart-width;
    position: relative;
}

.shoppingCartMultipleCart__cartButton {
    width: $shoppingCartMultipleCart-width;
    height: var(--sf-form-control-height);
    border-radius: var(--sf-border-radius);
    font-weight: var(--sf-font-weight-semibold);
    padding: 0 12px;
    text-align: center;
    line-height: var(--sf-form-control-height);
    user-select: none;
    @include text-overflow-ellipsis();
    @include blockButton--bg();
    @include blockButton--hoverable();

    &.--current {
        @include blockButton--current();
        color: var(--sf-text-color) !important;
    }

    &.--current.--custom {
        padding-right: $shoppingCartMultipleCart-formButton-width !important;
        cursor: text;
    }
}

.shoppingCartMultipleCart__count {
    position: absolute;
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
    padding-inline: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--sf-font-weight-bold);
    line-height: 1;
    background-color: #ddd;
    color: var(--sf-text-color);
    top: -11px;
    right: 18px;
    font-size: $font-size-075rem;
    &.--current {
        background-color: var(--sf-text-color);
        color: var(--sf-body-bg-color);
    }
}


#shoppingCartMultipleCart__formAdd,
#shoppingCartMultipleCart__formDelete,
#shoppingCartMultipleCart__formRename {
    position: absolute;
    right: 0;
    top: 0;
    height: var(--sf-form-control-height);
}

.shoppingCartMultipleCart__formInput {
    width: $shoppingCartMultipleCart-width !important;
    box-sizing: border-box;
    padding-right: $shoppingCartMultipleCart-formButton-width !important;
    font-size: var(--sf-font-size) !important;
    font-weight: var(--sf-font-weight-semibold) !important;
    text-align: center;
}

#shoppingCartMultipleCart__formRename .shoppingCartMultipleCart__formInput {
    @include formControl--focus();
}

.shoppingCartMultipleCart__formButton {
    appearance: none;
    border: none;
    position: absolute;
    background: none;
    right: 0;
    top: 0;
    width: $shoppingCartMultipleCart-formButton-width;
    height: var(--sf-form-control-height);
    color: var(--sf-line-color-strong);
    display: grid;
    align-items: center;
    justify-content: center;

    &.--delete {
        color: var(--sf-text-light-color);
        @include inside-svgicon-scale(.5);
    }

    @media (hover) {
        &:hover {
            color: var(--sf-text-color);
        }
    }
}