@import "../constants";

@mixin verticalMarginBottom {
    margin-bottom: 2lh;
    @media (max-width: $screen-600px-1) {
        margin-bottom: 1lh;
    }
}

@mixin verticalMarginTop {
    margin-top: 2lh;
    @media (max-width: $screen-600px-1) {
        margin-top: 1lh;
    }
}