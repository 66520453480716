@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/buttonLinkMixins";


.vehicleLine {
    display: flex;
    align-items: stretch;
    margin: 16px 0;
}

.vehicleLine__vehicleImage {
    img {
        object-fit: contain;
        object-position: center center;
        user-select: none;
        display: block;
        border-radius: var(--sf-border-radius);
        -webkit-user-drag: none;
    }

    &.--small {
        margin-inline-end: 16px;
        img {
            width: 88px;
            height: 32px;
        }
    }

    &.--medium {
        //border-inline-end: 1px solid var(--sf-line-color-100);
        margin-inline-end: 24px;
        img {
            height: 80px;
        }
    }
}

.vehicleLine__details {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.vehicleLine__detail {
    padding-inline-end: 16px;
    &.--selectAnotherVehicle {
        padding-inline-end: 0;
        margin-inline-start: auto;
    }
}

.vehicleLine__vehicleNameLink {
    @include link();
    @include linkUnderlineOnHover();
    font-weight: var(--sf-font-weight-semibold);
}

.vehicleLine__bullet {
    padding: 0 4px;
}