@import "../constants";
@import "../mixins/blockButtonMixins";

.articleVehiclesListWrapper {
    display: block;
}

.articleVehiclesList__header {
    color: var(--sf-heading-2-color);
    border-bottom: none !important;
    padding: 0 !important;
    margin: 16px 0;
}

.manufacturerItem {
    height: 64px;
    padding: 2px;
    @media (max-width: $screen-600px-1) {
        width: 50%;
    }

    .manufacturerItem__link {
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        font-weight: var(--sf-font-weight-bold);
        color: inherit;

        .manufacturerItem__image {
            height: 60%;
            width: 25%;
            object-fit: contain;
            display: inline-block;
        }

        @include blockButton();
        @include blockButton--hoverable();
    }

    &.manufacturerItem--selected {
        .manufacturerItem__link {
            @include blockButton--activeGreen();
        }
    }
}


.modelItemGroup {
    border-top-color: var(--sf-line-color-light);

    .modelItemGroup__shortName, .modelItemGroup__modelItemChunks {
        @media (max-width: $screen-600px-1) {
            width: 100%;
        }
    }

    &.modelItemGroup--odd {
        background-color: var(--sf-block-button-bg-color);
    }
}


.modelItem {
    @media (max-width: $screen-600px-1) {
        width: 100%;
    }

    .modelItem__link {
        @include blockButton(transparent);
        @include blockButton--hoverable();
    }
}

.vehicleItemsModal {
    background-color: var(--sf-block-button-bg-color);
}


.vehicleItem {
    .vehicleItem__link {
        .vehicleItem__title {
            color: var(--sf-link-color);
        }

        .vehicleItem__title--faded {
            opacity: .5;
        }

        @include blockButton--hoverable();
    }

    &.vehicleItem--odd {
        background-color: white;
    }
}