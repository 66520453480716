@import "../constants";
@import "../mixins/blockButtonMixins";

.missingPriceToFreeDelivery {
    visibility: hidden;
    margin-left: auto;
    margin-right: 0;
    padding: 8px;
    border-radius: var(--sf-border-radius);
    width: max-content;
    max-width: 100%;
    //border: 1px solid var(--sf-color-primary);
    //.block-shadow-colored(var(--sf-color-primary));
}

.missingPriceToFreeDelivery--active {
    visibility: visible;
}

.missingPriceToFreeDelivery__price {
    font-weight: 600;
    color: var(--sf-color-primary);
}