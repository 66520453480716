@import "../constants";
@import "../mixins/formMixins";

.shoppingCartCouponSystem__inputAndButton {
    display: flex;
    justify-content: stretch;
}

.shoppingCartCouponSystem__label {
    margin-inline-end: 6px;
    flex-grow: 1;
}

.shoppingCartCouponSystem__buttonWrapper {
    flex-basis: 20%;
    > input {
        width: 100%;
    }
}

.shoppingCartCouponSystem__error {
    @include formError();
}