@import "../constants";
@import "../mixins/colorMixins";

.tags {
    display: block;
    font-size: 0;

    &.--column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.tag {
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    padding: 0 calc(max(.35em, 6px));
    margin-bottom: 2px;
    margin-right: 2px;
    background-color: var(--sf-text-color);
    color: white;
    border-radius: var(--sf-border-radius);
    font-weight: var(--sf-font-weight-semibold);
    font-size: $font-size-075rem;
    height: var(--sf-tag-height);
    line-height: var(--sf-tag-height);
    box-sizing: content-box;
    letter-spacing: initial;
    text-transform: uppercase;
    box-shadow: var(--sf-block-shadow);

    &.--category {
        color: var(--sf-tag-category-text-color);
        background-color: var(--sf-tag-category-bg-color);
        box-shadow: var(--sf-tag-category-box-shadow);
        font-weight: var(--sf-tag-category-font-weight);
    }

    &.--blackFriday {
        color: var(--sf-tag-black-friday-text-color);
        background-color: var(--sf-tag-black-friday-bg-color);
        font-weight: var(--sf-font-weight-bold);
    }

    &.--new {
        color: var(--sf-tag-new-text-color);
        background-color: var(--sf-tag-new-bg-color);
        box-shadow: inset 0 0 0 1px var(--sf-tag-new-text-color), var(--sf-block-shadow);
        font-weight: var(--sf-font-weight-bold);
    }

    &.--inAction {
        background-color: var(--sf-tag-inAction-bg-color);
        color: var(--sf-tag-inAction-text-color);
        font-weight: var(--sf-font-weight-bold);
    }

    &.--discount {
        background-color: var(--sf-tag-discount-bg-color);
        color: var(--sf-tag-discount-text-color);
        font-weight: var(--sf-font-weight-bold);
    }

    &.--discountPercents {
        background-color: var(--sf-tag-discount-percents-bg-color);
        color: var(--sf-tag-discount-percents-text-color);
        font-weight: var(--sf-font-weight-bold);
        font-size: var(--sf-tag-discount-percents-font-size);
        height: var(--sf-tag-discount-percents-height);
        line-height: var(--sf-tag-discount-percents-height);
        @media (max-width: $screen-tablet) {
            height: var(--sf-tag-discount-percents-mobile-height);
            line-height: var(--sf-tag-discount-percents-mobile-height);
        }
    }

    &.--sale {
        background-color: var(--sf-tag-sale-bg-color);
        color: var(--sf-tag-sale-text-color);
    }

    &.--bazaar {
        background-color: var(--sf-tag-bazaar-bg-color);
        color: var(--sf-tag-bazaar-text-color);
    }

    &.--bestseller {
        background-color: var(--sf-tag-bazaar-bg-color);
        color: var(--sf-tag-bazaar-text-color);
    }

    &.--freeShipping {
        background-color: var(--sf-tag-freeShipping-bg-color);
        color: var(--sf-tag-freeShipping-text-color);
    }

    &.--recommended {
        background-color: var(--sf-tag-recommended-bg-color);
        color: var(--sf-tag-recommended-text-color);
    }

    &.--comingSoon {
        background-color: var(--sf-tag-comingSoon-bg-color);
        color: var(--sf-tag-comingSoon-text-color);
    }

    &.--mostWatched {
        background-color: var(--sf-tag-mostWatched-bg-color);
        color: var(--sf-tag-mostWatched-text-color);
    }

    &.--bundle {
        background-color: var(--sf-tag-bundle-bg-color);
        color: var(--sf-tag-bundle-text-color);
    }

    &.--fastest {
        color: var(--sf-tag-fastest-text-color);
        background-color: var(--sf-tag-fastest-bg-color);
        box-shadow: inset 0 0 0 1px var(--sf-tag-fastest-border-color), var(--sf-block-shadow);
        font-weight: var(--sf-font-weight-bold);
    }

    &.--cheapest {
        color: var(--sf-tag-cheapest-text-color);
        background-color: var(--sf-tag-cheapest-bg-color);
        box-shadow: inset 0 0 0 1px var(--sf-tag-cheapest-border-color), var(--sf-block-shadow);
        font-weight: var(--sf-font-weight-bold);
    }

}