@import "../constants";

.layoutHeaderDropdownMenu {
    max-width: $screen-900px;
}

.layoutHeaderDropdownMenu__currencySwitcher {
    @media (min-width: $screen-1200px) {
        display: none;
    }
}