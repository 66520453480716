@import "../constants";
@import "../mixins/headingMixins";

.heading, h1, h2, h3, h4, h5, h6 {
    @include heading;
}

.heading__secondLine {
    font-size: 1rem;
    font-weight: var(--sf-font-weight-semibold);
    display: inline-block;
    vertical-align: middle;
    @media (max-width: $screen-600px-1) {
        display: block;
        font-size: $font-size-088rem !important;
    }
}

.heading--margin {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.heading--h1, h1 {
    @include heading--h1;
}

.heading--h2, h2 {
    @include heading--h2;
}

.heading--h3, h3 {
    @include heading--h3;
}

.heading--h4, h4 {
    @include heading--h4;
}

.heading--h5, h5 {
    @include heading--h5;
}

.heading--h6, h6 {
    @include heading--h6;
}