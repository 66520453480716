@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/imageMixins";

.productLinked {
    appearance: none;
    @include blockButton();
    @include blockButton--hoverable();
    padding: 4px 8px 4px 4px;
    display: flex;

    &.--current {
        @include blockButton--current();
    }

    picture {
        width: 48px;
        height: 48px;
        border-radius: var(--sf-border-radius);
        overflow: hidden;
        //@include imageOverlay();
        margin-right: 12px;
        background-color: white;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.productLinked__details {
    align-self: center;
    flex-grow: 1;
    text-align: start;
}

.productLinked__name {
    &.--bazaar {
        color: $color-orange;
    }
}

.productLinked__availability {

}

.productLinked__prices {
    white-space: nowrap;
    align-self: center;
}