@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/imageMixins";
@import "../mixins/buttonLinkMixins";

$productGridProduct__xs-right-width: 4.25rem;

.productGrid {
    @media (min-width: $screen-600px) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
        gap: var(--sf-grid-nav-gap-block) var(--sf-grid-nav-gap);
    }
}

.productGridProduct {
    display: flex;
    width: 100%;
    align-items: stretch;
    height: auto;
    user-select: none;
    background-color: white;
    overflow: hidden;
    @media (max-width: $screen-600px-1) {
        &:not(:last-child) {
            padding-bottom: 20px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--sf-line-color-light);
        }
    }
    @media (min-width: $screen-600px) {
        @include blockButton();
        @include blockButton--hoverable();
        cursor: default;
        margin-bottom: var(--sf-product-grid-product-gap-block);
    }
}

.productGridProduct__inside {
    width: 100%;
    background-color: white;
    display: flex;
    @media (min-width: $screen-600px) {
        flex-direction: column;
    }
}

.productGridProduct__image {
    display: flex;
    box-sizing: content-box;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;
    background-color: white;
    width: 96px;
    height: 96px;
    padding: 10px;
    flex-shrink: 0;
    @media (min-width: $screen-600px) {
        width: auto;
        height: 164px;
        padding: 32px 16px;
    }

    picture {
        display: contents;

        .productGridProduct__image__img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            -webkit-user-drag: none;
            opacity: 0;
            transition: opacity $transition-duration;

            &.loaded {
                opacity: 1;
            }
        }
    }

    @include imageOverlay();
}

.productGridProduct__image__iconImg {
    transform: scale(.5);
}

.productGridProduct__image__brandIconImg {
    position: absolute;
    right: 8px;
    top: 8px;
    user-select: none;
    mix-blend-mode: multiply;
    max-width: 56px;
    height: auto;
    display: block;
    max-height: 32px;
    object-fit: contain;
    object-position: right top;
    pointer-events: none;
}

.productGridProduct__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-inline-start: 10px;
    @media (min-width: $screen-600px) {
        padding: 10px 10px 6px;
    }
}

.productGridProduct__tags {
    position: absolute;
    inset-inline-start: 2px;
    top: 2px;
    @media (min-width: $screen-600px) {
        inset-inline-start: 4px;
        top: 4px;
    }
}

.productGridProduct__heading {
    margin-bottom: 8px;
    font-size: var(--sf-heading-4-font-size);
    font-weight: var(--sf-font-weight-bold);
    user-select: auto;
    //min-height: 4.5ex;
    box-sizing: content-box;

    a {
        line-height: $line-height-135;
        @include linkUnderlineOnHover(var(--sf-product-name-link-text-color));
    }
}

.productGridProduct__shortText {
    padding-bottom: 4px;
    color: var(--sf-text-light-color);
    font-size: $font-size-088rem;
    user-select: auto;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 11ex;

    ul, p, li {
        display: inline;
        list-style-type: none;
    }

    br {
        display: none;
    }
}

.productGridProduct__parameters {
    padding-top: 4px;

    ul {
        margin: 0;
    }

    li {
        display: block;
        padding-inline-end: 12px;
        margin: 0;
        line-height: $line-height-150;

        span {
            opacity: .65;
        }

        b {
            font-weight: var(--sf-font-weight-bold);
        }

        a {
            @include btnLinkUnderlinedSubtleLine();
        }
    }

    @media (max-width: $screen-600px-1) {
        font-size: $font-size-075rem;
        letter-spacing: 0;
    }

    @media (min-width: $screen-600px) {
        font-size: $font-size-088rem;
    }
}

.productGridProduct__parameters__heading {
    font-size: inherit;
    margin: 6px 0;
}

.productGridProduct__showMore {
    margin-top: auto;
    margin-bottom: 4px;
}

.productGridProduct__showMore__link {
    color: inherit;
    text-decoration: underline;
    font-size: $font-size-088rem;
    font-weight: var(--sf-font-weight-semibold);
}

.productGridProduct__pricesAndBuy {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.productGridProduct__prices {
    padding: 0;
}

.productGridProduct__buy {
    margin-left: 16px;
    display: flex;
    justify-content: flex-end;
}

.productGridProduct__buy__button__text {
    margin: 0 8px;
}

.productGridProduct__buy .svgicon {
    transform: scale(.75);
    @media (max-width: $screen-600px-1) {
        display: none;
    }
}

.productGridProduct__availability {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid var(--sf-line-color-100);
    display: grid;
}

.productGridProduct__availability__tags {
    margin-bottom: 2px;
}