@import "../constants";

.shoppingCartLoginOrRegister {
    user-select: none;
    background-color: var(--sf-block-button-bg-color);
    //box-shadow: $block-shadow-default;
    border-radius: var(--sf-border-radius);
}

.shoppingCartLoginOrRegister__forms {
    justify-content: center;
    @media (min-width: $screen-900px) {
        display: flex;
    }
    @media (min-width: $screen-1200px) {
        align-items: stretch;
    }
}

.shoppingCartLoginOrRegister__form {
    position: relative;
    padding: 1rem;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    @media (min-width: $screen-900px) {
        padding: 2rem;
        &:first-child {
            border-right: 1px solid var(--sf-line-color-light);
        }
    }
    @media (max-width: $screen-900px-1) {
        &:last-child {
            border-top: 1px solid var(--sf-line-color-light);
        }
    }
}

.shoppingCartLoginOrRegister__formHeading {
    margin-bottom: 1rem;
}

.shoppingCartLoginOrRegister__formMainHeading {
    // cross style for .heading.heading--h2
    line-height: 1;
}

.shoppingCartLoginOrRegister__formButtons {
    margin-top: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: ($screen-400px - 1px)) {
        flex-wrap: wrap;
        > * {
            flex-basis: 100% !important;
            margin: 0 0 4px 0 !important;;
        }
    }
}

.shoppingCartLoginOrRegister__formButtons__btn {
    flex-basis: 50%;

    &:last-child {
        white-space: normal;
    }
}

.shoppingCartLoginOrRegister__spinnerWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}