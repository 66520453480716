@import "../constants";

.coupon {
    font-weight: var(--sf-font-weight-bold);
    height: 32px;
    padding-inline: 16px;
    font-size: 1rem;
    border-radius: var(--sf-border-radius);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    box-shadow: inset 0 0 0 2px var(--sf-coupon-border-color);
    background-color: var(--sf-coupon-bg-color);
    color: var(--sf-coupon-text-color);

    &:before, &:after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        height: 12px;
        width: 12px;
        background-color: var(--sf-body-bg-color);
        border-radius: 50%;
        box-shadow: 0 0 0 2px var(--sf-coupon-border-color);
    }

    &:before {
        left: -6px;
    }

    &:after {
        right: -6px;
    }
}