@import "../constants";
@import "../mixins/typeMixins";

.shoppingCartTotal {
    border-top: 4px solid var(--sf-line-color-light);
    @media (min-width: $screen-900px) {
        display: flex;
        justify-content: space-between;
        gap: 16px;
    }
}

.shoppingCartTotal__bonuses,
.shoppingCartTotal__prices {
    padding-top: 16px;
    flex-basis: 500px;
}

.shoppingCartTotal__bonuses {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}


.shoppingCartTotal__prices {
    order: 2;
    text-align: end;
    margin-inline-start: auto;
}

.shoppingCartTotal__prices__line {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    min-height: 24px;

    &.--primary {
        color: var(--sf-text-color);
        font-weight: var(--sf-font-weight-bold);
        font-size: $font-size-120rem;
    }

    &:not(.--primary) {
        font-size: $font-size-088rem;
        color: var(--sf-text-light-color);
    }

    &.--strikethrough {
        color: $color-red !important;
        font-size: 1rem;
        font-weight: var(--sf-font-weight-semibold);
        .price__primary {
            position: relative;
            @include text-strikethrough();
        }
    }

    &.--youSave {
        color: $color-green;
        font-size: 1rem;
        .price__primary {
            font-weight: var(--sf-font-weight-semibold);
        }
    }
}


.shoppingCartTotal__btnNext {
    display: block;
    width: 100%;
    @media (min-width: $screen-600px) {
        position: absolute;
        left: 25%;
        width: 50%;
        &:dir(rtl) {
            left: initial;
            right: 25%;
        }
    }
}