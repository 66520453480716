@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/typeMixins";

.vehicleModel {
}

.vehicleModel__header {
    display: grid;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--sf-line-color-100);
    @media (min-width: $screen-600px) {
        grid-template-columns: 1fr auto;
    }
    picture {
        display: contents;
    }
}

.vehicleModel__heading__modelCodes {
    display: block;
    font-size: 1rem;
    color: var(--sf-text-light-color);
    font-weight: var(--sf-font-weight-semibold);
}

.vehicleModel__header__image {
    display: block;
    -webkit-user-drag: none;
    height: 64px;
    width: 180px;
    margin-top: 8px;
    object-position: left center;
    object-fit: contain;
    margin-right: 24px;
    @media (min-width: $screen-600px) {
        margin-top: 0;
        object-position: right center;
    }
}

.vehicleModel__prompt {
    display: block;
    margin: 12px 0;
    color: var(--sf-text-light-color);
}

.vehicleModel__fuelNav__heading {
    line-height: 48px;
    margin-top: 16px;
}

.vehicleModel__vehicles {
    display: grid;
    gap: 7px 32px;
    @media (min-width: $screen-600px) {
        grid-template-columns: repeat(auto-fill, minmax(var(--sf-vehicle-model-vehicle-min-width), 1fr));
    }
}

.vehicleModel__vehicle {
    display: block;
    @include blockButton(transparent);
    @include blockButton--hoverable();
    padding: 4px 8px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        top: -5px;
        background: var(--sf-line-color-100);
    }
}

.vehicleModel__vehicle__nameAndPower {
    font-weight: var(--sf-font-weight-bold);
    display: flex;
}

.vehicleModel__vehicle__name {
    color: var(--sf-text-color);
    font-size: $font-size-110rem;
    @media (max-width: $screen-600px-1) {
        margin-inline-end: auto;
    }
}

.vehicleModel__vehicle__power {
    color: var(--sf-link-color);
    font-weight: var(--sf-font-weight-semibold);
    white-space: nowrap;

    &:before {
        @include bulletContent(4px);
    }
}

.vehicleModel__vehicle__yearAndMotorCodes {
    display: flex;
    color: var(--sf-text-light-color);
    align-items: baseline;
}

.vehicleModel__vehicle__years {
    white-space: nowrap;
}

.vehicleModel__vehicle__motorCodes {
    opacity: .65;
    font-size: $font-size-088rem;
    margin-inline-start: 12px;
}