@import "../constants";


.dropdownLoader {
    padding: 4px;
}

.dropdownLoader__text {
    display: block;
    line-height: 1;
    text-align: center;
    font-weight: var(--sf-font-weight-bold);
    font-size: var(--sf-heading-4-font-size);
    padding: .5rem 0;
}

.dropdownLoader__spinner {
    padding: .5rem 0;
}