@use "sass:math";

@import "../constants";
@import "../mixins/svgiconMixins";

.shoppingCartPlan {
    border-bottom: 4px solid var(--sf-line-color-light);
    user-select: none;
}

.shoppingCartPlan__container {
    color: var(--sf-line-color-strong);
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-between;
}

.shoppingCartPlan__arrow {
    display: none;
    @media (min-width: $screen-600px) {
        display: inline-block;
    }

    .svgicon {
        @include svgicon-color(var(--sf-line-color-strong));
        @include svgicon-size(24px * 0.5);
        @media (min-width: $screen-1200px) {
            @include svgicon-size(math.div(24px, 1.5));
        }
    }
}

.shoppingCartPlan__step {
    text-align: center;
    display: none;
    align-items: center;
    //.font-spacing-narrow();
    font-size: var(--sf-heading-5-font-size);
    color: var(--sf-line-color-strong);
    @media (max-width: $screen-600px-1) {
        font-size: var(--sf-heading-2-font-size);
        color: var(--sf-heading-2-color);
    }
    @media (min-width: $screen-600px) {
        display: flex;
    }
    @media (min-width: $screen-900px) {
        font-size: var(--sf-heading-4-font-size);
    }
    @media (min-width: $screen-1200px) {
        font-size: var(--sf-heading-3-font-size);
    }

    &:first-child {
        text-align: left;
    }

    &:last-child {
        text-align: right;
    }

    &.--current {
        color: var(--sf-text-color) !important;
        font-weight: var(--sf-font-weight-bold);
        display: flex;
    }

    &.--done {
        color: $color-green !important;
    }
}

.shoppingCartPlan__number {
    color: currentColor;
    display: inline-block;
    font-weight: var(--sf-font-weight-semibold);
    font-size: 1rem;
    width: 40px;
    text-align: left;
    @media (max-width: $screen-600px-1) {
        &:after {
            content: "/3";
        }
    }
    @media (min-width: $screen-600px) {
        font-weight: var(--sf-font-weight-bold);
        margin-right: .5em;
        text-align: center;
        border-radius: 100%;
        height: 24px;
        width: 24px;
        line-height: 24px;
        background-color: currentColor;
        span {
            color: white;
        }
    }
}

.shoppingCartPlan__linkToStep {
    color: inherit !important;

    @media (hover) {
        &:hover {
            .shoppingCartPlan__step.--current &, .shoppingCartPlan__step.--done & {
                text-decoration: underline;
            }
        }
    }
}