@import "../mixins/buttonLinkMixins";

.buttonsPagePart {
    --buttons-page-part-gap: 12px;
    gap: var(--buttons-page-part-gap);
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &.--start {
        justify-content: flex-start;
    }

    &.--center {
        justify-content: center;
    }

    &.--end {
        justify-content: flex-end;
    }
}

.buttonsPagePart__button {
    --buttons-page-part-columns: 1;

    @for $i from 2 through 6 {
        &.--#{$i} {
            --buttons-page-part-columns: #{$i};
        }
    }

    width: calc((100% / var(--buttons-page-part-columns)) - var(--buttons-page-part-gap) + (var(--buttons-page-part-gap) / var(--buttons-page-part-columns)));

    &.--default {
        @include btnDefault();
    }

    &.--primary {
        @include btnPrimary();
    }

    &.--extraSmall {
        @include btnExtraSmall();
    }

    &.--small {
        @include btnSmall();
    }

    &.--large {
        @include btnLarge();
    }

    &.--extraLarge {
        @include btnExtraLarge();
    }
}