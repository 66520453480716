.accordion {
    border-bottom: 1px solid var(--sf-line-color-100);
}

.accordion__title {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 16px 8px 16px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: white;
}

.accordion__title:hover, .accordion__title[aria-expanded="true"] {
    background-color: var(--sf-pane-bg-color);
}

.accordion__title:before {
    content: '\002B'; /* + */
    font-size: 24px;
    font-weight: var(--sf-font-weight-normal);
    margin-right: 16px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.accordion__title[aria-expanded="true"]:before {
    content: '\2212'; /* - */
}

.accordion__content {
    padding: 16px 8px;
    font-size: 14px;
    color: var(--sf-text-color-a75);
    line-height: 1.5;
}
