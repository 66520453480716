@import "../constants";

.price__alts {
    opacity: .65;
    font-weight: var(--sf-font-weight-normal);
    font-size: $font-size-088rem;
    margin-inline-start: .75em;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

.price__alt:not(:first-child):before {
    content: ' ,';
}

.price__vatPhrase {
    margin-inline-start: .75em;
    opacity: .65;
    font-weight: var(--sf-font-weight-normal);
    font-size: $font-size-088rem;
}