@use "sass:math";

@import "../constants";
@import "../mixins/imageMixins";
@import "../mixins/blockButtonMixins";
@import "../mixins/buttonLinkMixins";

$productInline__xs-right-width: 4.25rem;

.productInline {
    position: relative;
    display: flex;
    transition-property: background-color, border-color;
    transition-duration: .2s;
    margin: var(--sf-product-inline-gap-block) 0;

    //&:not(:last-of-type) {
    //    &:after {
    //        position: absolute;
    //        bottom: -10px;
    //        left: 0;
    //        content: '';
    //        height: 2px;
    //        background-color: var(--sf-line-color-light);
    //        width: 100%;
    //    }
    //}

    padding: 4px;
    @include blockButton();
    //@include blockButton(transparent);
    @include blockButton--hoverable();

    .dropdown {
        @media (min-width: $screen-600px) {
            width: 40%;
        }
    }

}

.productInline__main {
    padding: 8px 0 0;
    flex-grow: 1;
    flex-direction: column;
    display: flex;
}

.productInline__image {
    display: flex;
    padding: 0;
    border-radius: var(--sf-border-radius);
    overflow: hidden;
    width: 25%;
    max-height: 128px;
    flex-shrink: 0;
    margin-inline-end: 16px;
    @media (min-width: $screen-760px) {
        height: 160px;
        max-height: unset;
        left: 0;
        right: auto;
        width: 192px;
    }

    &:dir(rtl) {
        right: auto;
        left: 0;
    }

    a {
        display: flex;
        justify-content: center;
        max-height: 100%;
        width: 100%;
        padding: 12px;
        background-color: white;
        @include imageOverlay;

        picture {
            display: contents;

            .productInline__image__img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                -webkit-user-drag: none;
                @media (min-width: $screen-760px) {
                    width: 100%;
                    height: 100%;
                }

                &.loaded {
                    opacity: 1;
                }
            }
        }
    }
}

.productInline__image__iconImg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    align-self: center;
}

.productInline__image__brandIconImg {
    position: absolute;
    right: 8px;
    top: 8px;
    user-select: none;
    mix-blend-mode: multiply;
    max-width: 56px;
    height: auto;
    display: block;
    max-height: 32px;
    object-fit: contain;
    object-position: right top;
    pointer-events: none;
}

.productInline__heading {
    font-weight: var(--sf-font-weight-bold);
    line-height: $line-height-120;
    margin-bottom: 8px;
    margin-inline-end: 8px;

    @media (min-width: $screen-600px) {
        margin-inline-start: $productInline__xs-right-width;
    }
    @media (max-width: $screen-600px-1) {
        font-size: 1rem;
        margin-inline-end: $productInline__xs-right-width;
    }
    @media (min-width: $screen-600px) {
        font-size: var(--sf-heading-4-font-size);
        margin-inline-start: 0 !important;
        margin-inline-end: 0 !important;
    }
    @media (min-width: $screen-1200px) {
        font-size: $font-size-120rem;
    }

    a {
        color: var(--sf-product-name-link-text-color);
    }
}

.productInline__tags {
    margin-bottom: 4px;
}

.productInline__shortText {
    max-height: 10ex;
    overflow: hidden;
    opacity: .65;

    p:not(:last-child), ul:not(:last-child), ol:not(:last-child) {
        margin-bottom: 10px;
    }

    @media (max-width: $screen-600px-1) {
        line-height: $line-height-120;
    }
    @media (min-width: $screen-600px) {
        margin: 8px 0;
    }

    hr {
        display: none;
    }

    hot {
        display: inline;
        font-weight: var(--sf-font-weight-bold);
        color: var(--sf-hot-text-color);
        background-color: var(--sf-hot-bg-color);
        box-shadow: 0 0 0 3px var(--sf-hot-bg-color);
        border-radius: var(--sf-border-radius);
    }

    .autodilyPemaProductNote {
        display: block;
        font-weight: var(--sf-font-weight-bold);
        color: var(--sf-hot-text-color);
    }
}

.productInline__parameters {
    //background-color: var(--sf-pane-bg-color);
    border-top: 1px solid var(--sf-line-color-light);
    padding-top: 4px;

    ul {
        margin: 0;
    }

    li {
        display: block;
        //border-bottom: 1px solid var(--sf-line-color-light);
        padding-inline-end: 12px;
        margin: 4px 0;
        //white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
        line-height: $line-height-120;

        span {
            opacity: .65;
        }

        b {
            font-weight: var(--sf-font-weight-bold);
        }

        a {
            @include btnLinkUnderlinedSubtleLine();
        }
    }

    @media (max-width: $screen-600px-1) {
        font-size: $font-size-075rem;
        letter-spacing: 0;
    }

    @media (min-width: $screen-600px) {
        font-size: calc(1rem - 1px);
        ul {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            //gap: 16px;
            //grid-template-rows: masonry;
        }
    }
}

.productInline__parameters__heading {
    font-size: inherit;
    //font-weight: inherit;
    margin: 4px 0;
}

.productInline__productSpecialInfo {
    display: flex;
    flex-wrap: wrap;
}

.productInline__shoppingLine {
    //border-top: 1px solid var(--sf-line-color-light);
    padding: 8px 4px;
    box-sizing: content-box;
    position: relative;
    min-height: 2.5rem;
    @media (min-width: $screen-760px) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        pointer-events: none;
        border-radius: var(--sf-border-radius);
    }

    > * {
        position: relative;
    }

    &.--bgColor:before {
        opacity: .125;
    }

    &.--bgColor.--red:before {
        background-color: var(--sf-availability-label-red-text-color) !important;
    }

    &.--bgColor.--orange:before {
        background-color: var(--sf-availability-label-orange-text-color) !important;
    }

    &.--bgColor.--green:before {
        background-color: var(--sf-availability-label-green-text-color) !important;
    }

    &.--bgColor.--teal:before {
        background-color: var(--sf-availability-label-teal-text-color) !important;
    }

    &.--bgColor.--blue:before {
        background-color: var(--sf-availability-label-blue-text-color) !important;
    }

    &.--bgColor.--grey:before {
        background-color: var(--sf-text-color) !important;
    }
}

.productInline__shoppingLine__availability {
    min-height: 2.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media (min-width: $screen-600px) {
        flex-basis: 25%;
    }
}

.productInline__shoppingLine__pricesSimple {
    padding: 0 12px;
    @media (min-width: $screen-1200px) {
        display: none;
    }
}

.productInline__shoppingLine__pricesFull {
    flex-basis: 50%;
    @media (max-width: $screen-1200px-1) {
        display: none;
    }
}

.productInline__shoppingLine__addToCart {
    display: flex;
    flex-basis: 300px;
    @media (max-width: $screen-600px-1) {
        margin-top: 1rem;
    }
}

.productInline__shoppingLine__addToCart__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;

    .svgicon {
        @include svgicon-size(20px);
        margin: 0 .5rem;
    }
}

.productInline__shoppingLine__addToCartCount {
    width: 100px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    span:first-child {
        margin-inline-end: 6px;
    }
}

.productInline__supplyOption {
    position: relative;
    border-radius: var(--sf-border-radius);
    padding: 4px;
    margin-bottom: 2px;
    @media (min-width: $screen-760px) {
        display: flex;
        gap: 12px;
    }

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        pointer-events: none;
        border-radius: var(--sf-border-radius);
    }

    > * {
        position: relative;
    }

    &.--fastest:before {
        background-color: var(--sf-supply-option-fastest-bg-color) !important;;
        opacity: 1 !important;
    }

    &.--cheapest:before {
        background-color: var(--sf-supply-option-cheapest-bg-color) !important;
        opacity: 1 !important;
    }

    &.--bgColor:before {
        opacity: .125;
    }

    &.--bgColor.--red:before {
        background-color: var(--sf-availability-label-red-text-color) !important;
    }

    &.--bgColor.--orange:before {
        background-color: var(--sf-availability-label-orange-text-color) !important;
    }

    &.--bgColor.--green:before {
        background-color: var(--sf-availability-label-green-text-color) !important;
    }

    &.--bgColor.--teal:before {
        background-color: var(--sf-availability-label-teal-text-color) !important;
    }

    &.--bgColor.--blue:before {
        background-color: var(--sf-availability-label-blue-text-color) !important;
    }

    &.--bgColor.--grey:before {
        background-color: var(--sf-text-color) !important;
    }
}

.productInline__supplyOption__tags {
    margin-bottom: 2px;
}

.productInline__supplyOption__availabilityHalf {
    flex-basis: 50%;
    gap: 0 8px;
}

.productInline__supplyOption__deliveryDateTime {
    margin-left: 22px;
    line-height: $line-height-120;
}

.productInline__supplyOption__priceAddToCartHalf {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $screen-760px-1) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.productInline__supplyOption__price {
    padding: 4px;
}

.productInline__supplyOption__addToCart {
    display: flex;
    min-width: 200px;
    @media (max-width: $screen-760px-1) {
        width: 100%;
    }
}

.productInline__supplyOption__addToCart__numberInput {
    width: 88px;
    margin-right: 4px;
}

.productInline__supplyOption__addToCart__btn {
    flex-grow: 1;
}