@import "../constants";

.picture__illustrative {
    position: absolute;
    place-self: center center;
    font-size: $font-size-088rem;
    background-color: rgba(255, 255, 255, .5);
    padding: 4px;
    backdrop-filter: blur(5px);
    border-radius: var(--sf-border-radius);
    color: var(--sf-text-color);
}