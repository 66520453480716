@import "../constants";
@import "../mixins/containerMixins";
@import "../mixins/typeMixins";
@import "../mixins/buttonLinkMixins";
@import "../mixins/imageMixins";

html {
    &.--mobileApp {
        .pagePart {
            display: none;

            &.--deviceType-mobileApp,
            &.--deviceType-all {
                display: block;
            }
        }
    }

    &:not(&.--mobileApp) {
        .pagePart {
            &.--deviceType-mobileApp {
                display: none !important;
            }
        }
    }
}

.page {
    @include container();
}

.pagePart {
    margin: 0 0 8px;
    display: none;
    container-name: pagePartContainer;
    container-type: inline-size;

    &--accordion {
        margin: 0;
    }
}

@media (max-width: $screen-tablet-1) {
    .pagePart--displayMobile {
        display: block;
    }
    .pagePart--hideMobile {
        display: none !important;
    }
}

@media (min-width: $screen-tablet) and (max-width: $screen-desktop-1) {
    .pagePart--displayTablet {
        display: block;
    }
    .pagePart--hideTablet {
        display: none !important;
    }
}

@media (min-width: $screen-desktop) {
    .pagePart--displayDesktop {
        display: block;
    }
    .pagePart--hideDesktop {
        display: none !important;
    }
}

html.--mobileApp {
    @media (max-width: $screen-tablet-1) {
        .pagePart--displayMobile {
            display: block !important;
        }

        .pagePart {
            &.--deviceType-mobileApp,
            &.--deviceType-all {
                display: none;
            }
        }
    }

    @media (min-width: $screen-tablet) and (max-width: $screen-desktop-1) {
        .pagePart--displayTablet {
            display: block !important;
        }

        .pagePart {
            &.--deviceType-mobileApp,
            &.--deviceType-all {
                display: none;
            }
        }
    }

    @media (min-width: $screen-desktop) {
        .pagePart--displayDesktop {
            display: block !important; // Show on desktop width if --displayDesktop
        }

        .pagePart {
            &.--deviceType-mobileApp,
            &.--deviceType-all {
                display: none; // Override to hide if no --displayDesktop
            }
        }
    }
}

.page.--pageType-layoutFooter {
    padding: 16px 0;
}

.page.--pageType-layoutSidebar {
    padding-top: 16px;

    .page {
        margin: 0 !important;
    }
}

.page.--pageType-category {
    margin: 16px 0;
}

.page.--pageType-layoutProductDetailBuy,
.page.--pageType-layoutProductDetail {
    margin: 16px 0;
}

.htmlPagePart {
    @include htmlContent();
}

.pagePart--html.--pageType-layoutHeader .htmlPagePart {
    border-radius: var(--sf-border-radius);
    padding: 4px 12px;
    background-color: rgba(0, 0, 0, .035);
    font-size: $font-size-088rem;
    @media (min-width: $screen-760px) {
        font-size: 1rem;
        text-align: center;
    }
}

.pagePart--iconImageSlider {
    overflow: hidden;
}

.imageGalleryPagePart__wrapper.--displayType-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(var(--layout-main-column-width) - 8px), 1fr));
    gap: 8px;

    .imageGalleryPagePart__imageWrapper {
        aspect-ratio: 1/1;
        align-items: center;
        justify-content: center;
        display: flex;

        picture {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            img {
                background-color: white;
                border-radius: var(--sf-border-radius);
                display: block;
                width: 100%;
                height: 100%;
                max-width: 80%;
                max-height: 80%;
                object-fit: contain;
                -webkit-user-drag: none;
                opacity: 0;
                transition: opacity $transition-duration;

                &.loaded {
                    opacity: 1;
                }
            }
        }
    }
}

.imageGalleryPagePart__imageWrapper {
    @include imageOverlay();
    @include imageEnlargeCursor();
}

.pagePart--htmlCode {
    margin: 0 0 2px !important;
}

.pagePart--news {
    margin-bottom: 48px !important;
}

.weboutPlayerPagePart {
    webout-player {
        width: 100%;
        border-radius: var(--sf-border-radius);
        overflow: hidden;
        background-color: var(--sf-pane-bg-color);
    }
}

.filePagePart__link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}

.filePagePart__text {
    font-weight: var(--sf-font-weight-semibold);
    @include linkUnderlineOnHover();
}

.pagePartContactForm {
    max-width: 540px;
}

.videoPagePart {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    border-radius: var(--sf-border-radius);
}

.pagePartProductGroup,
.imageSliderPagePart {
    overflow: hidden;
    //@container pagePartContainer (width <= #{$screen-1040px-1}) {
    @media (max-width: $screen-desktop-1) {
        overflow: visible;
    }
    //@container pagePartContainer (width >= #{$screen-1040px}) {
    @media (min-width: $screen-desktop) {
        overflow: hidden;
        &.--useOverflowOnDesktop {
            overflow: visible;
        }
    }
}