@import "../constants";

.vehicleSelectedSearchProductFilter {

}

.vehicleSelectedSearchProductFilter__vehicleSelection {
    margin-bottom: 16px;
}

.vehicleSelectedSearchProductFilter__resultInfo {
    //padding: 12px;
    //border-radius: var(--sf-border-radius);
    color: $color-red;
    margin-bottom: 16px;
    //font-weight: var(--sf-font-weight-bold);
}