@import "../constants";

.cell {
    display: flex;

    &.--border-right {
        border-right: 1px solid var(--sf-line-color-light);
    }

    &.--opacity-50 {
        opacity: .5;
    }

    &.--text-bold {
        font-weight: var(--sf-font-weight-bold);
    }

    &.--columnReverse {
        flex-direction: column-reverse;
    }
}