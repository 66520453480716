@import "../constants";

@mixin font-spacing-narrow() {
    letter-spacing: -0.05em;
    word-spacing: 0.12em;
}

@mixin font-spacing-default() {
    letter-spacing: initial;
    word-spacing: initial;
}

@mixin text-overflow-ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin text-strikethrough() {
    &:before {
        position: absolute;
        content: "";
        left: -8px;
        top: 50%;
        right: -8px;
        border-top: 1px solid currentColor;
        transform: rotate(-5deg);
        opacity: .35;
    }
}

@mixin bulletContent($padding-inline: 8px) {
    content: '●';
    padding: 0 $padding-inline;
    opacity: .25;
    transform: scale(.5);
    display: inline-block;
}

@mixin htmlContent($fontSize: var(--sf-font-size-large)) {
    font-size: $fontSize;

    * {
        // https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/
        line-height: unquote("calc(2px + 3ex + 2px)");
    }

    blockquote {
        font-size: var(--sf-heading-4-font-size);
        font-style: italic;
        padding-left: 1em;
        border-left: 4px solid var(--sf-line-color-light);
        opacity: .75;
    }

    h1, h2, h3, p, ul, ol, pre, blockquote, figure, img {
        margin-top: 0;
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    a {
        text-decoration: underline;
        //text–decoration–skip: ink;
    }

    p {
        margin-bottom: 1lh;
    }

    ul, ol {
        li {
            margin-left: 24px;

            .svgicon {
                @include svgicon-size(12px);
                @include svgicon-color(var(--sf-text-light-color));
            }
        }

        ul, ol {
            margin-bottom: 0;
        }
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-height: 300px;
        max-width: 100%;

    }

    iframe {
        width: 100%;
        aspect-ratio: 16/9;
    }

    small {
        font-size: inherit;
        opacity: .65;
    }

    //noinspection CssInvalidHtmlTagReference
    hot {
        display: inline;
        font-weight: var(--sf-font-weight-bold);
        color: var(--sf-hot-text-color);
        background-color: var(--sf-hot-bg-color);
        box-shadow: 0 0 0 3px var(--sf-hot-bg-color);
        border-radius: var(--sf-border-radius);
    }

    .autodilyPemaProductNote {
        display: block;
        font-weight: var(--sf-font-weight-bold);
        color: $color-orange;
    }
}