@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/svgiconMixins";

.shoppingCartLoginRegisterContinue {
    user-select: none;
    margin-inline-end: -8px;
    @media (min-width: $screen-600px) {
        display: flex;
        justify-content: stretch;
        flex-wrap: wrap;
    }
}

.shoppingCartLoginRegisterContinue__option {
    margin-top: 8px;
    margin-inline-end: 8px;
    flex-grow: 1;
    @media (min-width: $screen-600px) {
        min-width: 360px;
        flex-basis: 30%;
    }
}

.shoppingCartLoginRegisterContinue__link {
    padding: 8px;
    @include blockButton();
    @include blockButton--hoverable();
    display: flex;
    &.--login {
        color: $color-green;
    }
    &.--register {
        color: $color-orange;
    }
    &.--continue {
        color: var(--sf-text-light-color);
    }
    &.--active {
        @include blockButton--activeGreen();
    }
}

.shoppingCartLoginRegisterContinue__icon {
    padding-inline-start: 8px;
    padding-inline-end: 16px;
    align-self: stretch;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    .svgicon {
        @include svgicon-size(32px);
    }
}

.shoppingCartLoginRegisterContinue__text {
    white-space: nowrap;
}