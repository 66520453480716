@import "../constants";
@import "../mixins/containerMixins";

.sidebarLayout {
    padding-top: 16px;
    @include container();

    // remove container-* to prevent sticky sidebar side-jumping
    container-name: none;
    container-type: normal;

    .page,
    .containerLayout {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.sidebarLayout.--withSidebar {
    @media (min-width: $screen-desktop) {
        display: flex;
    }

    .sidebarLayout__sidebar {
        display: none;
        order: 0;
        @media (min-width: $screen-desktop) {
            will-change: min-height;
            min-width: 256px;
            display: block;
            width: 25%;
            flex-shrink: 0;
        }
    }

    .sidebarLayout__main {
        order: 1;
        @media (min-width: $screen-desktop) {
            //clip-path: polygon(-8px -8px, 10000% -8px, 10000% 100%, -8px 100%); // Refactor articleVehicleList to km_modal
            margin-inline-start: auto;
            width: 75%;
        }
    }
}

.sidebarLayout__sidebar__sticky {
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
    box-sizing: content-box;
}

.sidebarLayout__sidebar__inner {
    padding-inline-end: 32px;
    max-width: 320px;
    box-sizing: content-box;
}

.mobileAppHeader {
    & ~ .sidebarLayout {
        padding-top: 36px;
    }
}