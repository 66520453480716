@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/typeMixins";


@keyframes chatBubbleDisplay {
    from {
        opacity: 0;
        transform: translateY(56px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chatBubble {
    appearance: none;
    border: none;
    opacity: 0;
    animation: chatBubbleDisplay $transition-duration forwards;
    position: fixed;
    right: 12px;
    bottom: 24px;
    z-index: var(--ksfdef-z-index-chat-bubble);
    background-color: black;
    color: white;
    height: 56px;
    min-width: 56px;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--sf-font-weight-semibold);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 32px rgba(0, 0, 0, 0.1);
    transform-origin: right center;

    &.--1 {
        bottom: 88px; // 24px bottom + 56px height + 8px gap
    }

    &.--2 {
        bottom: 152px; // 24px bottom + 2x 56px height + 2x 8px gap
    }

    &.--whatsApp {
        background-color: #25D366;
        background-image: linear-gradient(135deg, #25D366, #128C7E);
        color: white;
    }

    .svgicon {
        @include svgicon-size(32px);
        position: absolute;
        right: 12px;
        top: 12px;
    }

    > span:first-child {
        box-sizing: border-box;
        max-width: 0;
        white-space: nowrap;
        overflow: hidden;
        transition-duration: $transition-duration;
        transition-property: max-width, padding-right, padding-left, opacity;
        opacity: 0;
    }

    @media (hover) {
        &:hover {
            > span:first-child {
                padding-right: 56px;
                padding-left: 16px;
                opacity: 1;
                max-width: calc(100vw - 96px); // 100vw - (bubble.width + 2x bubble.right + text.padding-left)
            }
        }
    }
}