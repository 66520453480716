@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/headingMixins";

.yearSlider {
    --sf-year-slider-height: var(--sf-form-control-height);
    --sf-year-slider-thumb-width: 80px;
    margin-bottom: 32px;
}

.yearSlider__heading {
    @include heading();
    @include heading--h5();
    text-align: center;
    margin-bottom: 8px;
    display: block;
}

.yearSlider__slider {
    display: flex;
    height: var(--sf-year-slider-height);
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: var(--sf-pane-bg-color);
    border-radius: var(--sf-border-radius);
}

.yearSlider__edgeYear {
    font-size: $font-size-088rem;
    font-weight: var(--sf-font-weight-bold);
    color: var(--sf-text-color);
    padding: 0 8px;
}

.yearSlider__range {
    position: relative;
    flex-basis: 100%;
    align-items: center;
    height: var(--sf-year-slider-height);
    display: flex;
}

.yearSlider__input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    outline: none;
    background-color: transparent;
    cursor: col-resize;
}

@mixin yearSlider__input__track {
    height: 2px;
    width: 100%;
    background-color: var(--sf-line-color-strong);
}

.yearSlider__input::-ms-track {
    @include yearSlider__input__track();
}

.yearSlider__input::-ms-track {
    @include yearSlider__input__track();
}

.yearSlider__input::-webkit-slider-runnable-track {
    @include yearSlider__input__track();
}

@mixin yearSlider__input__thumb {
    width: var(--sf-year-slider-thumb-width);
    height: var(--sf-year-slider-height);
    background-color: var(--sf-form-control-bg-color);
    background-image: var(--sf-form-control-bg-image);
    cursor: col-resize;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .15), var(--sf-block-shadow);
    border-radius: var(--sf-border-radius);
}

.yearSlider__input::-ms-thumb {
    @include yearSlider__input__thumb();
}

.yearSlider__input::-webkit-slider-thumb {
    @include yearSlider__input__thumb();
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    top: calc(var(--sf-year-slider-height)  / -2 + 1px);
}

.yearSlider__input::-moz-range-thumb {
    @include yearSlider__input__thumb();
}

.yearSlider__current {
    position: absolute;
    width: var(--sf-year-slider-thumb-width);
    text-align: center;
    pointer-events: none;
    display: flex;
    align-items: center;
    padding: 0 4px;
    .svgicon {
        pointer-events: none;
        position: relative;
        flex: 0 0 16px;
        @include svgicon-size(16px);
        opacity: .35;
    }
}

.yearSlider__currentYear {
    color: var(--sf-form-control-text-color);
    font-size: 1rem;
    font-weight: var(--sf-font-weight-bold);
    letter-spacing: -0.3px;
    width: var(--sf-year-slider-thumb-width);
    text-align: center;
    pointer-events: none;
}