@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/headingMixins";
@import "../mixins/imageMixins";

.searchAutocomplete {

}

.searchAutocomplete__content {
    display: grid;
    gap: 3px;
    justify-content: stretch;
}

.searchAutocomplete__heading {
    font-weight: var(--sf-font-weight-semibold);
    color: var(--sf-text-light-color);
    height: 32px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-088rem;

    .svgicon {
        transform: scale(.8);
    }

    strong {
        padding-left: 8px;
    }
}

.searchAutocomplete__group:not(:last-of-type) {
    margin-bottom: 8px;
    padding-bottom: 4px;
    position: relative;
}

.searchAutocomplete__group__heading {
    @include heading--h6;
    height: 32px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.searchAutocomplete__item {
    display: flex;
    align-items: center;
    height: 56px;
    font-weight: var(--sf-font-weight-normal);
    position: relative;
    line-height: 1;
    font-size: .9rem;
    color: var(--sf-text-color);
    margin: 4px 0;
    padding-inline: 4px;
    @include blockButton(transparent);
    @include blockButton--hoverable();

    &:focus {
        @include blockButton--current();
    }

    &.--showAll {
        height: 40px;
        //justify-content: center;
        font-weight: var(--sf-font-weight-semibold);
        color: var(--sf-link-color);
    }
}

.searchAutocomplete__item:not(:last-child):after {
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 1px;
    background-color: var(--sf-line-color-light);
    content: '';
}

.searchAutocomplete__item__imageWrap {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--sf-border-radius);
    @include imageOverlay();

    .svgicon {
        display: none;
        opacity: .5;
    }

    picture {
        display: contents;
    }

    img {
        width: 80%;
        height: 80%;
        object-fit: contain;
        display: block;
        background-color: white;
        border-radius: var(--sf-border-radius);
        flex-shrink: 0;

        &[hidden] {
            display: none;
        }
    }
}

.searchAutocomplete__item__text {
    flex-grow: 1;
    display: grid;
    gap: 6px;
}

.searchAutocomplete__item__heading {
    font-weight: var(--sf-font-weight-semibold);
}

.searchAutocomplete__item__subHeading {
    display: block;
    color: var(--sf-text-light-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchAutocomplete__item__availabilityLabel {
    &.--ableToOrder {
        color: var(--sf-availability-label-able-to-order-text-color);
    }

    &.--available {
        color: var(--sf-availability-label-available-text-color);
    }

    &.--onRequest {
        color: var(--sf-availability-label-on-request-text-color);
    }

    &.--red {
        color: var(--sf-availability-label-red-text-color) !important;
    }

    &.--orange {
        color: var(--sf-availability-label-orange-text-color) !important;
    }

    &.--green {
        color: var(--sf-availability-label-green-text-color) !important;
    }

    &.--teal {
        color: var(--sf-availability-label-teal-text-color) !important;
    }

    &.--blue {
        color: var(--sf-availability-label-blue-text-color) !important;
    }

    &.--grey {
        color: var(--sf-text-color) !important;
    }

    @media (max-width: $screen-tablet-1) {
        display: none;
    }
}

.searchAutocomplete__item__price {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 64px;
    font-weight: var(--sf-font-weight-bold);
    margin-inline-start: 12px;
}

.searchAutocomplete__item > .svgicon {
    flex-shrink: 0;
    transform: scale(.5);
    opacity: .5;
}