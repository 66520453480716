@use "sass:math";


@mixin arrow-color($color){
    &:before {
        background-image: linear-gradient(-45deg, $color 50%, transparent 50%);
    }
}

@mixin arrow-bottom($size, $color: black, $left: 50%){
    &:before {
        display: block;
        content: " ";
        bottom: -$size;
        left: $left;
        transform: rotate(45deg);
        height: 2 * $size;
        width: 2 * $size;
        position: absolute;
        margin-left: -$size;
        border-radius: 0 0 math.round($size * 0.6) 0;
        z-index: -1;
    }
    @include arrow-color($color);
}

@mixin arrow-top($size, $color, $left: 50%){
    &:before {
        display: block;
        content: " ";
        top: -$size;
        left: $left;
        transform: rotate(-135deg);
        height: 2 * $size;
        width: 2 * $size;
        position: absolute;
        margin-left: -$size;
        border-radius: 0 0 math.round($size * 0.6) 0;
        z-index: -1;
    }
    @include arrow-color($color);
}

@mixin arrow-inline-end($size, $color, $top: 50%){
    &:before {
        display: block;
        content: " ";
        top: $top;
        height: 2 * $size;
        width: 2 * $size;
        position: absolute;
        margin-top: -$size;
        border-radius: 0 0 math.round($size * 0.6) 0;
        z-index: 1;
    }
    &:dir(ltr):before {
        right: -$size;
        transform: rotate(-45deg);
    }
    &:dir(rtl):before {
        left: -$size;
        transform: rotate(135deg);
    }
    @include arrow-color($color);
}
