@import "../constants";


kvik-vin {
    display: flex;
    box-sizing: border-box;
    border-radius: var(--sf-border-radius);
    border: 1px solid var(--sf-line-color-100);
    background-color: white;
    height: var(--sf-btn-sm-height);
    padding: 0 4px;
    font-weight: var(--sf-font-weight-semibold);
    justify-content: center;
    align-items: center;
    letter-spacing: .2em;
    font-size: $font-size-088rem;
    color: var(--sf-text-light-color);
}