@import "../constants";
@import "../mixins/blockButtonMixins";

.vehicleManufacturers {
    user-select: none;
}

.vehicleManufacturers__section {
    position: relative;
    display: grid;
    gap: 4px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--sf-line-color-light);
        margin-bottom: 8px;
        padding-bottom: 8px;
    }

    @media (min-width: $screen-400px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $screen-600px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $screen-900px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.vehicleManufacturers__heading {
    // cross style for .heading
    position: absolute;
    left: 0;
    top: .15em;
    margin: 0;
    line-height: 1;
    color: var(--sf-text-color);

    &:dir(rtl) {
        left: initial;
        right: 0;
    }
}

.vehicleManufacturers__link {
    margin-inline-start: 34px;
    display: inline-flex;
    align-items: center;
    border-radius: var(--sf-border-radius);
    padding: 8px;
    font-weight: var(--sf-font-weight-semibold);
    @include blockButton(transparent);
    @include blockButton--hoverable();

    &--more {
        @media (max-width: ($screen-520px - 1)) {
            display: none;
        }
    }
}

.vehicleManufacturers__logo {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    display: var(--sf-vehicle-manufacturer-list-logo-display);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: ($screen-520px - 1)) {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}

.vehicleManufacturers__showMoreBtn {
    display: none;
    @media (max-width: ($screen-520px - 1)) {
        display: inline-block;
        border: none;
        background: transparent none;
        margin: 0 0 0 34px;
        padding: 2px 6px;
        color: var(--sf-text-color);
        font-size: $font-size-088rem;
        text-align: inherit;
    }
}