@use "sass:math";
@import "../constants";

@mixin blinkerDot($color: $color-green) {
    @keyframes blinker {
        30% {
            opacity:0
        }

        60% {
            opacity:1
        }
    }
    width: 12px;
    height: 12px;
    content: "";
    background: $color;
    border-radius: 50%;
    animation: blinker 1.2s linear infinite;
}