@import "../constants";

html.--mobileApp body {
    padding-bottom: 60px;
}

.mobileAppNav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: var(--sf-mobile-app-nav-bg-color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: var(--sf-mobile-app-nav-box-shadow);
    z-index: var(--ksfdef-z-index-header);

    .mobileAppNav__item {
        flex: 1;
        text-align: center;
        color: var(--sf-mobile-app-nav-button-text-color);
        font-size: 12px;
        text-decoration: none;
        transition: color 0.3s;

        &.--active {
            color: var(--sf-mobile-app-nav-button-active-text-color);
        }
    }

    .mobileAppNav__icon {
        margin-bottom: 4px;
        display: flex;
        justify-content: center;
    }

    .mobileAppNav__label {
        font-size: 10px;
        font-weight: var(--sf-font-weight-semibold);
        display: block;
    }

    // Remove button styling
    button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        cursor: pointer;
        outline: none;
    }

    img {
        max-height: 24px;
        max-width: 24px;
        display: block;
        border-radius: 50%;
        box-shadow: var(--sf-block-shadow);
        background-size: cover;
    }
}

// Todo: Reconsider the following styles 🤔
.mobileAppNav {
    & ~ .dropdown {
        height: calc(100vh - 84px) !important; // Leave space for the mobile nav + breadcrumb
        transform: translate3d(0px, 24px, 0px) !important; // Leave space for the mobile breadcrumb
        position: fixed !important;

        .dropdown__content {
            top: auto;
            bottom: 0;
            position: absolute;
            transform-origin: center bottom !important;

            .cell > * {
                width: 100%;
            }
        }
    }
}

.mobileAppNav__hamburgerIcon {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: currentColor !important;
        opacity: 1;
        left: 0;
        transition: transform .5s $transition-timing-function, opacity .5s $transition-timing-function;
        border-radius: 1px;
        top: unquote("calc(50% - 1px)");

        &:nth-child(1) {
            transform: translateY(-8px);
        }

        &:nth-child(4) {
            transform: translateY(8px);
        }

        .mobileAppNav__item.--active & {
            &:nth-child(1), &:nth-child(4) {
                transform: translateY(0) scale(0);
                opacity: 0;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}