@import "../constants";

.categoryFilterSortViewNav {
    padding-bottom: 8px;
    border-bottom: 2px solid var(--sf-line-color-strong);
    display: flex;
    margin: 32px 0;
}

.categoryFilterSortViewNav__nav {
    &.--last {
        margin-inline-start: auto;
    }
}

.categoryFilterSortViewNav__viewStyle {
    display: none;
    @media (min-width: $screen-600px) {
        display: flex;
    }
}

.categoryFilterSortViewNav__viewStyle__btn {
    color: var(--sf-line-color-strong);
    appearance: none;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    &.--current {
        color: var(--sf-text-color);
    }
}

.categoryFilterSortViewNav__sort {
    display: none;
    @media (min-width: $screen-900px) {
        display: flex;
    }
}

.categoryFilterSortViewNav__sort__btn {
    color: var(--sf-line-color-strong);
    appearance: none;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--sf-font-weight-semibold);
    height: 32px;
    margin-inline-end: 16px;
    &.--current {
        color: var(--sf-text-color);
    }
    @media (hover) {
        &:hover {
            color: var(--sf-text-color);
            text-decoration: underline;
            text-underline-offset: .15em;
        }
    }
}

.categoryFilterSortViewNav__sortSelect {
    @media (min-width: $screen-900px) {
        display: none;
    }
}