@import "../constants";
@import "../mixins/scrollMixins";
@import "../mixins/svgiconMixins";

.scrollX {
    position: relative;
}

.scrollX__scrollable {
    @include scrollX();
}

.scrollX__arrow {
    position: absolute;
    top: 0;
    height: 100%;
    width: 32px;
    background-color: rgba(255, 255, 255, .9);
    backdrop-filter: blur(5px);
    appearance: none;
    border: none;
    transition: opacity $transition-duration;
    color: var(--sf-text-light-color);
    display: flex;
    align-items: center;
    justify-content: center;


    &.--end {
        inset-inline-end: -12px;
        border-inline-start: 1px solid var(--sf-line-color-100);
    }

    &.--start {
        inset-inline-start: -12px;
        border-inline-end: 1px solid var(--sf-line-color-100);
    }

    &[disabled] {
        opacity: 0;
        pointer-events: none;
    }

    .svgicon {
        @include svgicon-size(12px);
    }
}