@import "../constants";

.hidden-xs {
    @media (max-width: $screen-600px-1) {
        display: none !important;
    }
}

.hidden-sm {
    @media (min-width: $screen-600px) and (max-width: $screen-900px-1) {
        display: none !important;
    }
}

.hidden-md {
    @media (min-width: $screen-900px) and (max-width: $screen-1200px-1) {
        display: none !important;
    }
}

.hidden-lg {
    @media (min-width: $screen-1200px) {
        display: none !important;
    }
}