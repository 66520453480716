@import "../constants";

.pagePartProductGroup {
    user-select: none;
    margin-bottom: 16px;
}

.pagePartProductGroup__heading {
    margin-bottom: 1rem;
}

.pagePartProductGroup__products {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: $screen-600px) {
        grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    }

    &.--size-extraSmall {
        @media (max-width: $screen-600px-1) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.pagePartProductGroup__product {
    display: flex;
    align-items: stretch;
}