@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/buttonLinkMixins";

.articleContact ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 8px;
}

.articleContact li {
    display: flex;
    padding: 16px 20px;
    align-items: center;
    font-weight: var(--sf-font-weight-bold);
    background-color: var(--sf-pane-bg-color);
    border-radius: var(--sf-border-radius);
}

@media (hover) {
    .articleContact li a:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
    }
}


.articleContact__icon {
    .svgicon {
        @include svgicon-size(36px);
        @include svgicon-color(var(--sf-sidebar-nav-link-icon-color));
    }

    flex: 0 0 36px;
    padding-right: 20px;
}

.articleContact__mapAndContactForm {
    display: grid;
    gap: 48px;
    align-items: stretch;
    grid-template-columns: 1fr;
    @media (min-width: $screen-520px) {
        grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
    }
}

.articleContact__map {
    min-height: 360px;
    //max-height: 480px;
    display: flex;
    border-radius: var(--sf-border-radius);
    border: 1px solid var(--sf-line-color-strong);
    overflow: hidden;
    box-shadow: var(--sf-block-shadow);

    iframe {
        height: 100%;
        width: 100%;
        border: none;
    }
}

.articleContact__owner__heading {
    margin-bottom: 1rem;
}

.articleContact__owner__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 16px;
}

.articleContact__owner__cell {

}