@import "../constants";
@import "../mixins/typeMixins";
@import "../mixins/blockButtonMixins";


.articleList__article {
    background-color: var(--sf-article-list-article-bg-color);
    border-radius: var(--sf-border-radius);
    margin-bottom: calc(var(--sf-article-list-gap) + 1px);
    box-sizing: content-box;
    position: relative;
    padding: var(--sf-article-list-article-padding);
    display: grid; // to apply margin of inner elements

    &:after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: var(--sf-article-list-separator-line-color);
        width: 100%;
        bottom: calc((var(--sf-article-list-gap) / 2) * -1 - 1px);
        left: 0;
    }

    &.--wImage {
        display: grid;
        grid-template-columns: clamp(96px, 25%, 120px) 1fr;
    }
}

.articleList__article__image {
    display: flex;
    border-radius: var(--sf-border-radius);
    aspect-ratio: 1/1;
    background-color: var(--sf-pane-bg-color);
    overflow: hidden;
    position: relative;
}

.articleList__article__image:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: var(--sf-article-list-article-image-before-left);
    top: var(--sf-article-list-article-image-before-top);
    background: var(--sf-article-list-article-image-before-bg);
    transform: var(--sf-article-list-article-image-before-transform);
    transform-origin: var(--sf-article-list-article-image-before-transform-origin);
}

.articleList__article__image:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: var(--sf-article-list-article-image-after-left);
    top: var(--sf-article-list-article-image-after-top);
    background: var(--sf-article-list-article-image-after-bg);
    transform: var(--sf-article-list-article-image-after-transform);
    transform-origin: var(--sf-article-list-article-image-after-transform-origin);
}

.articleList__article__image:before {
    position: absolute;
    content: '';
}

.articleList__article__image__picture {
    width: 100%; // because of Chrome
}

.articleList__article__image__img {
    display: block;
    border-radius: var(--sf-border-radius);
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

.articleList__article__body__wrapper {
    position: relative;
    align-self: stretch;
    display: grid;
}

.articleList__article__body {
    padding-block: .5lh;
    padding-inline: 12px;
    align-self: center;
}

.articleList__article__header__time {
    opacity: .35;
    display: block;
    margin-top: .25lh;
}

.articleList__article__perex {
    display: none;
    margin: 0;
    line-height: $line-height;
    opacity: .75;
    font-weight: var(--sf-font-weight-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--sf-text-color);
    text-decoration: none;
    position: relative;
    max-height: 3lh;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 60ch;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 1lh, rgba(0,0,0,0) 3lh);
}

.articleList__article__bbb {
    @include blockButtonBorderElement(auto);
}

@container pagePartContainer (width >= 520px) {
    .articleList {
        display: grid;
        gap: 0 calc(var(--sf-article-list-gap) * 2);
        grid-template-columns: repeat(auto-fill, minmax(520px, 1fr));
        grid-template-rows: auto; /* Ensures row height adapts */
        align-items: stretch; /* Stretches children to match height */
    }

    .articleList__article.--wImage {
        grid-template-columns: clamp(100px, 35%, 224px) 1fr;

        .articleList__article__body {
            padding-block: 1lh;
            padding-inline: 24px;
            position: absolute;
            inset: 0 0 auto 0;
            max-height: 100%;
            overflow: hidden;
        }

        .articleList__article__perex {
            margin-top: .25lh;
            display: block;
            display: -webkit-box;
        }
    }
}