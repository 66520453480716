@import "../constants";

@mixin heading {
    font-weight: var(--sf-font-weight-extrabold);
    //letter-spacing: -0.025em;
    //word-spacing: 0.1em;
    margin: 0;
    line-height: 1.2;
    @media (max-width: $screen-600px-1) {
        //.font-spacing-narrow();
    }
}

@mixin heading--h1 {
    font-weight: var(--sf-heading-1-font-weight);
    font-size: var(--sf-heading-1-font-size);
    color: var(--sf-heading-1-color);
    font-style: var(--sf-heading-1-font-style);
    text-transform: var(--sf-heading-1-text-transform);
    letter-spacing: var(--sf-heading-1-letter-spacing);
    word-spacing: var(--sf-heading-1-word-spacing);
    //@media (max-width: $screen-600px-1) {
    //    line-height: $line-height-120;
    //    font-size: $font-size-h1 * .7;
    //}
    //@media (min-width: $screen-600px) and (max-width: $screen-1200px-1) {
    //    font-size: $font-size-h1 * .85;
    //}
}

@mixin heading--h2 {
    font-weight: var(--sf-heading-2-font-weight);
    font-size: var(--sf-heading-2-font-size);
    color: var(--sf-heading-2-color);
    font-style: var(--sf-heading-2-font-style);
    text-transform: var(--sf-heading-2-text-transform);
    letter-spacing: var(--sf-heading-2-letter-spacing);
    word-spacing: var(--sf-heading-2-word-spacing);
    @media (max-width: $screen-600px-1) {
        line-height: $line-height-120;
        font-size: var(--sf-heading-3-font-size);
    }
}

@mixin heading--h3 {
    font-weight: var(--sf-heading-3-font-weight);
    font-size: var(--sf-heading-3-font-size);
    color: var(--sf-heading-3-color);
    font-style: var(--sf-heading-3-font-style);
    text-transform: var(--sf-heading-3-text-transform);
    letter-spacing: var(--sf-heading-3-letter-spacing);
    word-spacing: var(--sf-heading-3-word-spacing);
    @media (max-width: $screen-600px-1) {
        //line-height: $line-height-120;
        font-size: var(--sf-heading-4-font-size);
    }
}

@mixin heading--h4 {
    font-weight: var(--sf-heading-4-font-weight);
    font-size: var(--sf-heading-4-font-size);
    color: var(--sf-heading-4-color);
    font-style: var(--sf-heading-4-font-style);
    text-transform: var(--sf-heading-4-text-transform);
    letter-spacing: var(--sf-heading-4-letter-spacing);
    word-spacing: var(--sf-heading-4-word-spacing);
}

@mixin heading--h5 {
    font-size: var(--sf-heading-5-font-size);
    color: var(--sf-heading-3-color);
}

@mixin heading--h6 {
    font-size: var(--sf-heading-6-font-size);
    color: var(--sf-text-light-color);
    font-weight: var(--sf-font-weight-semibold);
    text-transform: uppercase;
    letter-spacing: 1.6px;
}