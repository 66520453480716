@import "../constants";
@import "../mixins/buttonLinkMixins";
@import "../mixins/imageMixins";
@import "../mixins/headingMixins";
@import "../mixins/blockButtonMixins";

.categoryGridPagePart__nav {
    user-select: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--sf-grid-nav-gap-block) var(--sf-grid-nav-gap);
    @media (min-width: $screen-520px) {
        grid-template-columns: repeat(auto-fill, minmax(244px, 1fr));
    }

    &.--count-3 {
        @media (min-width: $screen-600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-tablet) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.--count-4 {
        @media (min-width: $screen-600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-tablet) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.--count-5 {
        @media (min-width: $screen-600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-tablet) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: $screen-desktop) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &.--count-6 {
        @media (min-width: $screen-600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-tablet) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: $screen-desktop) {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &.--count-7 {
        @media (min-width: $screen-600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-tablet) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: $screen-desktop) {
            grid-template-columns: repeat(7, 1fr);
        }
    }

    &.--count-8 {
        @media (min-width: $screen-600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-tablet) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: $screen-desktop) {
            grid-template-columns: repeat(8, 1fr);
        }
    }
}

.categoryGridPagePart__btn {
    position: relative;
    display: none !important;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    min-height: var(--sf-page-part-category-grid-btn-min-height) !important;
    color: var(--sf-page-part-category-grid-text-color);
    box-shadow: var(--sf-block-button-box-shadow);
    border-radius: var(--sf-border-radius);
    @include blockButton(var(--sf-page-part-category-grid-border-color), var(--sf-page-part-category-grid-bg-color));
    @include blockButton--hoverable();
    @include imageOverlay(var(--sf-page-part-category-grid-overlay-bg-color));
    //@media (max-width: $screen-520px-1) {
    //    margin-bottom: 3px;
    //    overflow: initial;
    //    &:before {
    //        position: absolute;
    //        height: 1px;
    //        width: 100%;
    //        bottom: calc(-1 * var(--sf-grid-nav-gap-block));
    //        left: 0;
    //        content: '';
    //        background-color: var(--sf-line-color-100);
    //    }
    //}
    @media (min-width: $screen-600px) {
        min-height: var(--sf-page-part-category-grid-btn-min-height) !important;
    }

    &.--displayMobile {
        @media (max-width: $screen-tablet-1) {
            display: flex !important;
        }
    }

    &.--displayTablet {
        @media (min-width: $screen-tablet) and (max-width: $screen-desktop-1) {
            display: flex !important;
        }
    }

    &.--displayDesktop {
        @media (min-width: $screen-desktop) {
            display: flex !important;
        }
    }
}

.categoryGridPagePart__btn.--vehicleType {
    @media (min-width: $screen-1200px) {
        display: none !important;
    }
}

.categoryGridPagePart__icon {
    display: none;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: 24px;
    margin-inline-end: 16px;
    @media (min-width: $screen-600px) {
        width: 48px;
    }

    picture {
        display: contents;
    }

    img {
        -webkit-user-drag: none;
        object-fit: contain;
        image-rendering: auto;
        -ms-interpolation-mode: bicubic;
    }
}

.categoryGridPagePart__name__name {
    font-size: 1rem;
    line-height: 1;
    color: inherit;
    display: inline-flex;
    text-transform: var(--sf-page-part-category-grid-name-name-text-transform);
    font-style: var(--sf-page-part-category-grid-name-name-font-style);
    font-weight: var(--sf-page-part-category-grid-name-name-font-weight) !important;
    letter-spacing: var(--sf-page-part-category-grid-name-name-letter-spacing);
    word-spacing: var(--sf-page-part-category-grid-name-name-word-spacing);
}

.categoryGridPagePart__name__suffix {
    line-height: 1;
    font-size: $font-size-088rem;
    opacity: .65;
    display: block;
    margin-top: 4px;
}

.categoryGridPagePart--textOnly {
    .categoryGridPagePart__nav {
        gap: var(--sf-grid-nav-gap-block) var(--sf-grid-nav-gap);
    }

    .categoryGridPagePart__name {
        text-align: center;
        padding: 0 16px;
    }
}

.categoryGridPagePart--above,
.categoryGridPagePart--left,
.categoryGridPagePart--start {
    .categoryGridPagePart__icon {
        display: flex;
    }
}

@mixin categoryGridPagePart--iconLeft() {
    @media (max-width: $screen-520px-1) {
        display: block;
    }

    .categoryGridPagePart__btn {
        text-align: left;
        padding-inline-start: 72px;
        justify-content: start;
        padding-inline-end: 16px;
    }

    .categoryGridPagePart__icon {
        height: 100%;
        width: 64px;
        display: flex;
        left: 0;
        right: 0;
        position: absolute;
        align-content: space-between;
        align-items: center;

        img {
            height: calc(max(var(--sf-page-part-category-grid-icon-left-size) / 1.25, 24px));
            width: calc(max(var(--sf-page-part-category-grid-icon-left-size) / 1.25, 24px));
            object-fit: contain;
            @media (min-width: $screen-520px) {
                width: var(--sf-page-part-category-grid-icon-left-size);
                height: var(--sf-page-part-category-grid-icon-left-size);
            }
        }

        .svgicon {
            @include svgicon-color(var(--sf-sidebar-nav-link-icon-color));
            @include svgicon-size(calc(max(var(--sf-page-part-category-grid-icon-left-size) / 1.5, 24px)));
            @media (min-width: $screen-520px) {
                @include svgicon-size(var(--sf-page-part-category-grid-icon-left-size));
            }
        }
    }
}

.categoryGridPagePart--left,
.categoryGridPagePart--start {
    @include categoryGridPagePart--iconLeft();
}

.categoryGridPagePart--above {
    @media (max-width: $screen-520px-1) {
        @include categoryGridPagePart--iconLeft();
    }
    @media (min-width: $screen-520px) {
        .categoryGridPagePart__icon {
            width: unset;
            margin-inline-end: unset;
            margin-top: 8px;
            flex-basis: 64px;
            flex-shrink: 1;

            img {
                max-height: var(--sf-page-part-category-grid-icon-above-size);
                max-width: 80%;
            }

            .svgicon {
                @include svgicon-color(var(--sf-sidebar-nav-link-icon-color));
                @include svgicon-size(var(--sf-page-part-category-grid-icon-above-size));
            }
        }

        .categoryGridPagePart__btn {
            min-height: var(--sf-page-part-category-grid-icon-above-button-height) !important;
            line-height: unset !important;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            aspect-ratio: var(--sf-page-part-category-grid-icon-above-aspect-ratio);
        }

        .categoryGridPagePart__name {
            min-height: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0 12px;
            margin-bottom: 8px;
        }
    }
}

.categoryGridPagePart__tag {
    position: absolute;
    right: 4px;
    top: 6px;
}