@import "../constants";
@import "../mixins/buttonLinkMixins";
@import "../mixins/headingMixins";

.km-modal__content {
    h2 {
        @include heading;
        @include heading--h2;
        margin-bottom: 1rem;
        text-align: center;
    }

    .km-newsletterSubscriberControl, .km-loginControl, .km-surrogateControl {
        form {
            margin-bottom: 0;

            input[type=submit] {
                margin-top: 0;
                @include btnLarge;
            }
        }
    }

    .km-surrogateControl {
        .km-surrogateControl-actualShoppingIdentity {
            text-align: center;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--sf-line-color-light);
            margin-bottom: 15px;
            @media (min-width: $screen-600px) {
                font-size: $font-size-110rem;
            }
        }
    }
}