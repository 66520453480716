@import "../constants";
@import "../mixins/typeMixins";

.productPricesSimple {
    font-size: $font-size-100rem;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    white-space: nowrap;
    flex-direction: column;

    &.--end {
        align-items: flex-end;
    }
}

.productPricesSimple__strikethroughPrice {
    color: $color-red;
    font-weight: var(--sf-font-weight-semibold);
    font-size: $font-size-088rem;

    .price__primary, .price__alt {
        position: relative;
        @include text-strikethrough();
    }
}

.productPricesSimple__userPrice {
    color: var(--sf-text-color);
    font-weight: var(--sf-font-weight-bold);
    @media (min-width: $screen-600px) {
        font-size: $font-size-110rem;
    }
}

.productPricesSimple__vatSuffix {
    color: var(--sf-text-light-color);
    font-size: $font-size-088rem;
    margin-left: 4px;
}