@use "sass:math";


@mixin imageOverlay($bgColor: var(--sf-image-overlay-bg-color)) {
    position: relative;
    &:after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-color: $bgColor;
        border-radius: var(--sf-border-radius);
        pointer-events: none;
    }
}

@mixin imageEnlargeCursor() {
    cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='32' height='32' rx='16' fill='%23111'/%3e%3cpath d='m18.05 15.363-1.414-1.414L19.585 11H17V9h6v6h-2v-2.587l-2.95 2.95ZM11 19.587l2.95-2.95 1.414 1.414L12.415 21H15v2H9v-6h2v2.587Z' fill='%23fff'/%3e%3c/svg%3e") 32 32, pointer !important;
}