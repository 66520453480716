@use "sass:math";

@import "../constants";
@import "../mixins/countryFlagMixins";
@import "../mixins/blockButtonMixins";
@import "../mixins/formMixins";
@import "../mixins/headingMixins";

#kvikymart-scdpa {
    margin-top: 3rem;
}

#kvikymart-scdpa-country-nav {
    user-select: none;
    /* Fix because some older Android devices renders the form in a glitchy way */
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0);

    h2 {
        margin: 12px 0;
        font-size: var(--sf-heading-3-font-size);
    }

    ul {
        margin: 12px 0;
        display: grid;
        gap: 4px;
        grid-template-columns: repeat(1, 1fr);
        @media (min-width: $screen-360px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-400px) {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }
    }

    li {
        display: block;
    }

    a {
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 48px;
        @include blockButton();
        @include blockButton--hoverable();
        //font-size: $font-size-088rem;
        font-weight: var(--sf-font-weight-bold);
        @media (min-width: $screen-600px) {
            font-weight: var(--sf-font-weight-bold);
        }

        .kvikymart-scdpa-country-flag {
            @include countryFlag();
            margin-inline-end: 12px;
        }

        &.active {
            @include blockButton--activeGreen();
        }
    }
}


#kvikymart-scdpa-delivery-payment-form {
    user-select: none;
    margin: 0 0 1rem;
    border-top: 4px solid var(--sf-line-color-light);
    border-bottom: 4px solid var(--sf-line-color-light);
    @media (min-width: $screen-900px) {
        margin: 0;
        display: flex;
    }
}

#kvikymart-scdpa-delivery,
#kvikymart-scdpa-payment {
    margin: 0;
    width: 100% !important;

    > legend {
        @include heading;
        @include heading--h3;
        line-height: 1;
        margin: 0;
        border: none;
        padding: 1rem 0 1rem 40px;
        //color: var(--sf-color-primary);
    }

    fieldset {
        border: none;
        padding: 0;
    }

    @media (min-width: $screen-900px) {
        width: 50% !important;
        padding-bottom: 3rem;
        > legend {
            padding-inline-start: 4rem;
            padding-top: 3rem;
        }
    }
}

#kvikymart-scdpa-delivery {
    @media (min-width: $screen-900px) {
        padding-inline-end: 2rem;
    }
}

#kvikymart-scdpa-payment {
    @media (min-width: $screen-900px) {
        padding-inline-start: 1.75rem;
        border-inline-start: 1px solid var(--sf-line-color-light);
    }
}

#kvikymart-scdpa-submit {
    display: none;
}

.kvikymart-scdpa-payment-type {
    margin: 0 !important;

    > legend {
        display: none;
    }
}

.kvikymart-scdpa-payment-logos {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    img {
        max-height: 16px;
        height: 100%;
        width: auto;
        @media (max-width: $screen-760px) {
            max-height: 12px;
        }
    }
}

.kvikymart-scdpa-delivery-item,
.kvikymart-scdpa-payment-item {
    position: relative;
    min-height: 3rem;
    margin-inline-start: 4rem;
    margin-bottom: 4px;
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-block-button-bg-color);
    @include blockButton();
    @include blockButton--hoverable();

    label {
        cursor: pointer;
        margin: 0;
        display: block;
        font-weight: var(--sf-font-weight-bold);
        line-height: 1.35;
        min-height: 3rem;
        padding: .9rem 70px .9rem 8px;
        border-radius: var(--sf-border-radius);
        //-webkit-tap-highlight-color: var(--sf-color-primary);

        &:focus {
            outline: none !important;
        }

        .kvikymart-scdpa-item-checkbox {
            position: absolute;
            width: 40px;
            left: 0;
            top: .75rem;
            text-align: center;

            &:dir(rtl) {
                right: 0;
                left: initial;
            }
        }

        .kvikymart-scdpa-item-image {
            position: absolute;
            left: -4rem;
            top: 0;
            height: 3rem;
            display: flex;
            align-items: center;
            transition: opacity .2s;

            &:dir(rtl) {
                right: -4rem;
                left: initial;
            }

            > img {
                max-height: 3rem;
                max-width: 3.5rem;
                width: 100%;
                height: auto;
            }
        }
    }

    .kvikymart-scdpa-item-name {
        padding-inline-start: 32px;
        display: table-cell;
    }

    @media (max-width: $screen-900px-1) {
        margin-inline-start: 40px;
        label {
            .kvikymart-scdpa-item-image {
                left: -40px;

                &:dir(rtl) {
                    right: -40px;
                    left: initial;
                }

                > img {
                    max-width: 36px;
                    width: 100%;
                    height: auto;
                }
            }
        }

        background-color: var(--sf-block-button-bg-color);
        //-webkit-tap-highlight-color: var(--sf-color-primary);
        cursor: pointer;
        margin-bottom: 4px;
        label {
            font-size: $font-size-088rem;
        }
    }

    .kvikymart-scdpa-price {
        position: absolute;
        right: .5rem;
        top: 0;
        font-weight: var(--sf-font-weight-bold);
        color: var(--sf-text-color);
        display: block;
        line-height: 3rem;

        &:dir(rtl) {
            right: initial;
            left: .5rem;
        }

        @media (max-width: $screen-900px-1) {
            font-size: $font-size-088rem;
        }
    }

    .kvikymart-scdpa-price-free {
        //font-weight: var(--sf-font-weight-normal);
        color: $color-green;
    }

    .kvikymart-scdpa-stores-selection {
        display: none;
    }

    .kvikymart-scdpa-item-subLabel {
        padding: 4px 0 0 32px;
        opacity: .75;
        font-weight: var(--sf-font-weight-normal);
        font-size: 1rem;

        * {
            margin: 0;
        }
    }

    .kvikymart-scdpa-delivery-item__excludedReason {
        font-size: $font-size-075rem;
        text-decoration: none;
        font-weight: var(--sf-font-weight-normal);
        margin-inline-start: 32px;
    }

    &.disabled, &--excluded {
        background-color: var(--sf-pane-bg-color);

        &, &:hover {
            opacity: .75;
            box-shadow: none !important;

            &, * {
                cursor: not-allowed !important;
            }
        }

        label {
            cursor: default !important;
            box-shadow: none !important;
            background-color: transparent !important;
            color: rgba(#232323, math.div(50%, 100.0%)) !important;

            .kvikymart-scdpa-item-image {
                opacity: .15;
            }
        }

        .kvikymart-scdpa-price, .kvikymart-scdpa-item-name {
            text-decoration: line-through;
            color: rgba(#232323, math.div(50%, 100.0%)) !important;
        }
    }

    &.selected {
        @include blockButton--activeGreen();

        .kvikymart-scdpa-stores-selection {
            margin: 0 8px 8px;
            display: block;

            select {
                @include formControl();
                @include formControl--select();
                width: 100%;
                font-size: $font-size-088rem;
                -webkit-appearance: none;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                background-color: var(--sf-color-primary) !important;
            }
        }
    }
}

.kvikymart-scdpa-deliveries-subLabel,
.kvikymart-scdpa-payments-subLabel {
    background-color: var(--sf-pane-bg-color);
    margin: 8px 0 8px 4rem;
    padding: 12px;
    border-radius: var(--sf-border-radius);
}

#kvikymart-scdpa-address {
    display: contents;
    #snippet-deliveryPaymentAddress-address {
        display: contents;
    }
    fieldset {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .kvikymart-no-message {
        text-align: center;
    }
}