

@mixin clearfix {
    &:after {
        content: '';
        display: block;
        clear: both;
        height: 0;
    }
}
