@use "sass:math";

@import "../constants";
@import "../mixins/typeMixins";
@import "../mixins/blockButtonMixins";
@import "../mixins/buttonLinkMixins";

.filters {

}

.filters__disable {
    margin-top: calc(var(--sf-btn-xs-height) * 0.5);
    border-top: 1px solid var(--sf-line-color-light);
    text-align: center;
    line-height: 1;
}

.filters__disableLink {
    position: relative;
    top: calc(var(--sf-btn-xs-height) / -2);
    @include btnDefault;
    @include btnExtraSmall;
    font-weight: var(--sf-font-weight-semibold);
    @include font-spacing-default();
    cursor: pointer;

    @media (hover) {
        &:hover {
            text-decoration: none !important;
        }
    }
}

.filters__disableLinkCross {
    opacity: .5;
    margin-left: 8px;
}

.filter {
    padding: 12px 0;

    &:not(:first-of-type) {
        border-top: 1px solid var(--sf-line-color-light);
    }
}

.filter__heading {
    display: block;
    font-size: $font-size-088rem;
    font-style: normal;
    color: var(--sf-text-color-a75);
    margin: 8px 4px;
    font-weight: var(--font-weight-bold);
}

.filter__list {
    display: grid;
    padding: 0;
    margin: 0;
    position: relative;
    font-size: $font-size-088rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    @media (min-width: $screen-520px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $screen-600px) {
        grid-template-columns: repeat(4, 1fr);
    }

    &.--productType {
        grid-template-columns: 1fr;
        @media (min-width: $screen-600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $screen-1200px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.filter__listItem {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 1px;

    &[hidden] {
        display: none;
    }

    &.--hidden {
        display: none;
    }
}

.filter__link {
    display: inline-block;
    color: var(--sf-text-color);
    @include blockButton(transparent);
    @include blockButton--hoverable();
    padding: 0 3px;
    cursor: pointer;
    font-weight: var(--sf-font-weight-semibold);

    &--selected, &.--selected {
        @include blockButton--bgCurrent();
    }
}

.filter__showAll {

}

.filter__count {
    margin-left: 4px;
    opacity: .5;
}

.filter__linkCross {
    margin-left: 4px;
    opacity: .75;
}

.filter__disabledItem {
    padding: 0 4px;
    opacity: .5;
}

.filters__submit {

}

.filter__list__showMoreBtn {
    margin-inline: 4px;
}