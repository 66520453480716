@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/imageMixins";
@import "../mixins/svgiconMixins";


.categorySearchResult {
    display: flex;
    align-items: center;
    height: 64px;
    font-weight: var(--sf-font-weight-normal);
    position: relative;
    line-height: 1;
    color: var(--sf-text-color);
    margin: 1px 0 4px;
    padding: 0 4px;
    @include blockButton(transparent);
    @include blockButton--hoverable();

    &:not(:last-child):after {
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 1px;
        background-color: var(--sf-line-color-light);
        content: '';
    }
}

.categorySearchResult__imageWrap {
    width: 56px;
    height: 56px;
    margin-right: 16px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--sf-border-radius);
    @include imageOverlay();

    .svgicon {
        opacity: .5;
    }

    picture {
        display: contents;
    }

    img {
        width: 80%;
        height: 80%;
        object-fit: contain;
        display: block;
        background-color: white;
        border-radius: var(--sf-border-radius);
        flex-shrink: 0;
    }
}

.categorySearchResult__text {
    height: 64px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.categorySearchResult__heading {
    font-weight: var(--sf-font-weight-semibold);
    color: var(--sf-link-color);
}

.categorySearchResult__subHeading {
    color: var(--sf-text-light-color);
    font-size: $font-size-088rem;
    display: flex;
    align-items: center;
    .svgicon {
        opacity: .35;
        margin-inline-start: 16px;
        @include svgicon-size(20px);
    }
}

.categorySearchResult > .svgicon {
    flex-shrink: 0;
    transform: scale(.5);
    opacity: .5;
    margin-inline-start: auto;
}