@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/imageMixins";
@import "../mixins/buttonLinkMixins";

.productCollection {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    justify-content: stretch;
    height: auto;
    user-select: none;
    margin-bottom: 16px;
    @include blockButton(transparent, var(--sf-product-collection-bg-color));
    @include blockButton--hoverable();

}

.productCollection__headingContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.productCollection__image {
    display: flex;
    aspect-ratio: 1/1;
    box-sizing: content-box;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;
    background-color: white;
    border-radius: var(--sf-border-radius);
    overflow: hidden;
    flex-shrink: 0;

    picture {
        display: contents;

        img {
            display: block;
            object-fit: contain;
            -webkit-user-drag: none;
            opacity: 0;
            transition: opacity $transition-duration;
            border-radius: var(--sf-border-radius);
            overflow: hidden;
            width: 80%;
            height: 70%;
            // todo for images with no white/transparent borders in the image itself
            //box-shadow: 0 4px 8px rgba(0, 0, 0, .2);

            &.loaded {
                opacity: 1;
            }
        }
    }

    @include imageOverlay();
}

.productCollection__tags {
    position: absolute;
    padding: 4px;
    box-sizing: border-box;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.productCollection__heading {
    padding: 12px 12px 6px;
    font-size: var(--sf-heading-5-font-size);
    font-weight: var(--sf-font-weight-semibold);
    line-height: $line-height-135;
    user-select: auto;

    a {
        color: var(--sf-product-name-link-text-color);
    }
}

.productCollection__pricesAvailability {
    margin-top: auto;
    padding: 0 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
}

.productCollection__price {
    margin-inline-end: 8px;
}

.productCollection__addToCart {
    user-select: none;
}

.productCollection__alreadyInCart {
    font-weight: var(--sf-font-weight-semibold);
}

.productCollection__addToCart__button {
    @include btnLinkUnderlinedSubtleLine;
    @include btnExtraSmall;
}

.productCollection__alreadyInCart,
.productCollection__addToCart__button {
    white-space: nowrap;
}


//region extraSmall

.productCollection.--size-extraSmall {
    flex-direction: row;
    flex-shrink: 0;

    .productCollection__image {
        height: 128px;
        width: 128px;
    }

    .productCollection__heading {
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    .productCollection__headingContainer {
        padding: 12px;
    }
}

//endregion