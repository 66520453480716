@import "../constants";

@keyframes skeleton-pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .25;
    }
    100% {
        opacity: 1;
    }
}

.skeleton {
    display: block;
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-skeleton-bg-color);
    will-change: transform;
    animation: skeleton-pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation-delay: 0.25s;
    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }
}

html.isBot .skeleton {
    display: none !important;
}

@mixin skeleton--fadeRight {
    background-image: linear-gradient(to right, transparent 50%, var(--sf-skeleton-fade-bg-color));
}

@mixin skeleton--fadeBottom {
    background-image: linear-gradient(to bottom, transparent 50%, var(--sf-skeleton-fade-bg-color));
}

@for $i from 1 through 10 {
    .skeleton:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1}s;
    }
    .skeleton > .skeleton:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1 + 0.2}s;
    }
}

.skeleton__productFilter {
    height: 66px;
    @include skeleton--fadeRight;
}

.skeleton__categoryFilterSortViewNav {
    height: 42px;
    margin-block: 32px;
    @include skeleton--fadeRight;
}

.skeleton__product {
    padding: 5px;
    display: flex;
    gap: 16px;
    flex-direction: row;

    &.--grid {
        margin-bottom: 16px;
        @media (min-width: $screen-600px) {
            flex-direction: column;
            margin-bottom: var(--sf-product-grid-product-gap-block);
            min-height: 425px;
            gap: 0;
            @include skeleton--fadeBottom;
        }
    }

    &.--inline {
        margin-block: var(--sf-product-inline-gap-block);
        min-height: 128px;
        @include skeleton--fadeRight;
    }
}

.skeleton__product__image {
    &.--grid {
        width: 96px;
        height: 96px;
        @media (min-width: $screen-600px) {
            width: auto;
            height: 228px;
        }
    }

    &.--inline {
        width: calc(min(25%, 192px));
        height: auto;
        aspect-ratio: 192/160;
    }
}

.skeleton__product__heading {
    height: calc(var(--sf-heading-4-font-size) * $line-height-120);
    max-width: 480px;
    flex-grow: 1;

    &.--grid {
        @media (min-width: $screen-600px) {
            flex-grow: 0;
            margin: 10px;
        }
    }

    &.--inline {
        margin: .5lh 0;
    }
}