@use "sass:math";

@import "../constants";
@import "./colorMixins";


@mixin blockButton($borderColor: var(--sf-block-button-border-color), $bgColor: white) {
    border-radius: var(--sf-border-radius);
    border: 1px solid $borderColor;
    background-color: $bgColor;
    //cursor: pointer;
    box-shadow: var(--sf-block-button-box-shadow);
}


@mixin blockButton--bg() {
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-block-button-bg-color);
    border: 1px solid var(--sf-block-button-bg-border-color);
    box-shadow: var(--sf-block-button-bg-box-shadow);
}

/** @deprecated Use blockButtonOverlayElement instead. */
@mixin blockButton--hoverable($borderColor: var(--sf-block-button-hover-border-color)) {
    //cursor: pointer;
    //will-change: box-shadow, border-color;
    @media (hover) {
        &:hover {
            box-shadow: 0 0 0 1px $borderColor, var(--sf-block-button-hover-box-shadow);
            border-color: $borderColor;
        }
    }
}

/** @deprecated Use blockButtonOverlayElement instead. */
@mixin blockButton--bgHoverable($bgColor: var(--sf-block-button-hover-border-color), $color: var(--sf-block-button-hover-text-color)) {
    //cursor: pointer;
    //will-change: box-shadow, border-color, color;
    @media (hover) {
        &:hover {
            color: $color;
            box-shadow: 0 0 0 1px $bgColor, var(--sf-block-button-hover-box-shadow);
            border-color: $bgColor;
            background-color: $bgColor;
        }
    }
}

@mixin blockButton--current($color: var(--sf-block-button-current-border-color), $backgroundColor: white) {
    box-shadow: 0 0 0 1px $color, var(--sf-block-button-hover-box-shadow) !important;
    border-color: $color !important;
    background-color: $backgroundColor !important;
}

@mixin blockButton--bgCurrent($bgColor: var(--sf-block-button-current-border-color), $color: var(--sf-block-button-hover-text-color)) {
    box-shadow: 0 0 0 1px $bgColor, var(--sf-block-button-hover-box-shadow) !important;
    border-color: $bgColor !important;
    background-color: $bgColor !important;
    color: $color;
}

@mixin blockButton--activeGreen() {
    @include blockButton--current($color-green);
    background-color: rgba($color-green, math.div(10%, 100.0%)) !important;
    color: var(--sf-text-color);
}

@mixin blockButtonBorderElement($pointerEvents: none) {
    position: absolute;
    inset: calc(-1 * var(--sf-block-button-border-offset));
    border-radius: inherit;
    z-index: 1;
    pointer-events: $pointerEvents;
    contain: layout style size;
    transition-property: transform, opacity;
    transition-duration: $transition-duration;
    opacity: var(--sf-block-button-border-start-opacity);
    transform: var(--sf-block-button-border-start-transform);
    content-visibility: hidden; // performance
    @media (hover) {
        *:hover > & {
            content-visibility: auto;
            box-shadow: inset 0 0 0 var(--sf-block-button-border-width) var(--sf-block-button-hover-border-color), var(--sf-block-button-hover-box-shadow);
            transform: none;
            opacity: 1;
        }
    }
}