@import "../constants";

.layoutHeaderDropdownContact {
    max-width: $screen-900px;
}

.layoutHeaderDropdownContact__contactFormWrapper {
    padding: 8px 16px;
    width: 360px;
    .km-form input[type=submit] {
        height: var(--sf-btn-height);
    }
}