@import "../mixins/svgiconMixins";
@import "../mixins/containerMixins";

html.--mobileApp:not(.--presenter-homepage) body {
    padding-top: var(--sf-mobile-app-header-height);
}

.mobileAppHeader {
    background-color: var(--sf-theme-color-mobile);
    height: var(--sf-mobile-app-header-height);
    box-shadow: var(--sf-mobile-app-header-box-shadow);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--ksfdef-z-index-header);
}

.mobileAppHeader__breadcrumb {
    @include container();
    display: flex;
    align-items: center;
}

.mobileAppHeader__breadcrumb {
    .kvikymart-breadcrumb-item {
        height: var(--sf-mobile-app-header-height);
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 12px;
        gap: 8px;
        font-weight: var(--sf-font-weight-semibold);
        color: var(--sf-mobile-app-header-link-color) !important;
    }

    .kvikymart-breadcrumb-separator {
        display: block;
        height: 12px;
        font-size: 0;

        .svgicon {
            @include svgicon-size(12px);
        }
    }
}