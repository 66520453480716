@import "../constants";
@import "../mixins/buttonLinkMixins";

.shoppingCartItems {
}

.shoppingCartItems__vehicle {
    &:not(:first-child) {
        border-top: 4px solid var(--sf-line-color-light);
    }
}

.shoppingCartItem + .shoppingCartItems__vehicle {
    border-bottom: none !important;
}

.shoppingCartItem {
    &:not(:first-child) {
        border-top: 1px solid var(--sf-line-color-light);
    }

    padding: 16px 0;
    position: relative;
    box-sizing: content-box;
    min-height: var(--sf-btn-height);
    padding-inline-start: 104px;
    @media (min-width: $screen-900px) {
        display: flex;
    }

    @media (max-width: $screen-520px-1) {
        padding-inline-start: 0;
    }
}

.shoppingCartItem__image {
    position: absolute;
    top: 16px;
    inset-inline-start: 0;

    img {
        display: block;
        width: 88px;
        height: var(--sf-btn-height);
        object-fit: contain;
        object-position: center center;
    }

    @media (max-width: $screen-520px-1) {
        display: none;
    }
}

.shoppingCartItem__cellMain {
    margin-inline-end: auto;
    padding-inline-end: 32px;
    align-self: center;
}

.shoppingCartItem__title {
    line-height: $line-height-120;
    font-weight: var(--sf-font-weight-bold);
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}

.shoppingCartItem__titleLink {
    @include linkUnderlineOnHover;
    color: var(--sf-text-color);
}

.shoppingCartItem__productAvailability {
    margin-top: 4px;
}

.shoppingCartItem__deliveryDateTime {
    font-size: $font-size-088rem;
    font-weight: var(--sf-font-weight-semibold);
    color: $color-green;
}

.shoppingCartItem__cellControls {
    display: flex;
    align-items: center;
    flex-basis: 400px;
    @media (max-width: $screen-900px-1) {
        margin-top: 8px;
    }
}

.shoppingCartItem__countFormAndItem {
    display: flex;
    align-items: center;
    height: var(--sf-btn-height);
    flex-shrink: 0;
}

.shoppingCartItem__countForm {
    width: 88px;
    margin-inline-end: 6px;
    height: 100%;
}

.shoppingCartItem__item {
    font-weight: var(--sf-font-weight-normal);
    white-space: nowrap;
    display: none;
    margin-inline-end: 16px;
    opacity: .5;

    b {
        font-weight: var(--sf-font-weight-bold);
    }

    @media (min-width: $screen-360px) {
        display: block;
    }
}

.shoppingCartItem__recyclingFee {
    color: var(--sf-text-light-color);
    font-size: $font-size-088rem;
}

.shoppingCartItem__priceAndRemoveForm {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--sf-btn-height);
    flex-shrink: 0;
    margin-inline-start: auto;
}

.shoppingCartItem__price {
    font-weight: var(--sf-font-weight-bold);
    white-space: nowrap;
}

.shoppingCartItem__item, .shoppingCartItem__price {
    .price {
        display: inline-grid;
    }

    .price__alts {
        margin: 0;
    }
}

.shoppingCartItem__priceWordFree {
    color: $color-green;
}

.shoppingCartItem__removeCell {
    margin-inline-start: 32px;
    width: 24px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.shoppingCartItem__cantOrderMessages {
    font-size: $font-size-088rem;
    font-weight: var(--sf-font-weight-semibold);
    list-style-position: inside;
    background-color: $color-red;
    color: white;
    margin: 8px 0;
    padding: 2px 12px;
    border-radius: var(--sf-border-radius);
}