@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/formMixins";
@import "../mixins/buttonLinkMixins";

.numberInput {
    display: flex;
    min-width: 88px;
    justify-content: stretch;
    align-items: stretch;
    background-color: var(--sf-block-button-bg-color);
    border-radius: var(--sf-border-radius);
    position: relative;
    height: 100%;

    input {
        @include formControl();
        height: 100%;
        padding: 0;
        text-align: center;
        display: inline-block;
        flex-grow: 1;
        width: 100%;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.numberInput__plus,
.numberInput__minus {
    position: absolute;
    top: 0;
    height: 100%;
    font-size: $font-size-110rem;
    width: 32px;
    border: none;
    padding: 0;
    border-radius: var(--sf-border-radius);
    color: var(--sf-text-light-color);
    background: transparent none;
    cursor: pointer;
    display: block;
    z-index: 1;

    &:not([disabled]):hover {
        box-shadow: var(--sf-block-shadow);
        background-color: var(--sf-form-control-text-color);
        color: white;
    }

    &[disabled] {
        cursor: not-allowed !important;
    }
}

.numberInput__plus {
    right: 0;
}

.numberInput__minus {
    left: 0;
}