@import "../constants";

.verticalMarginBottom {
    margin-bottom: 2.5rem;
    @media (max-width: $screen-600px-1) {
        margin-bottom: 1.5rem;
    }
}

.verticalMarginTop {
    margin-top: 2.5rem;
    @media (max-width: $screen-600px-1) {
        margin-top: 1.5rem;
    }
}