@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/buttonLinkMixins";

.productSpecialInfo {
    display: flex;
    padding-inline-end: 12px !important;

    &.--small {
        padding: 4px 0;
        font-size: $font-size-088rem;
    }

    &.--detail {
        //background-color: var(--sf-pane-bg-color);
        //margin: 6px 0;
        //border-radius: var(--sf-border-radius);
        padding: 8px;
    }
}

.productSpecialInfo__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 8px;
    color: var(--sf-product-special-info-text-color);
}

.productSpecialInfo.--small .productSpecialInfo__icon {
    .svgicon {
        @include svgicon-size(16px);
    }
}

.productSpecialInfo__body {
    flex-grow: 1;
    align-items: center;
    display: flex;
}

.productSpecialInfo__label {
    display: flex;
    font-weight: var(--sf-font-weight-semibold);
    a {
        @include link(var(--sf-product-special-info-text-color));
    }
    &.--detail a {
        @include linkUnderlinedSubtleLine();
    }
}

.productSpecialInfo__price {
    margin-inline-start: 8px;
    font-weight: var(--sf-font-weight-bold);
}