
@mixin scrollX(){
    //touch-action: pan-x;
    touch-action: manipulation;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
        width: 0;
        height: 0;
    }
}